import React from 'react'
import UserDashboardUI from './UserDashboardUI'
import { UserService } from '../../services/UserService';
import { useState } from 'react';
import { useEffect } from 'react';

function UserDashboard() {

    const [loading, setLoading] = useState(false);
    const [usersInfo, setUsersInfo] = useState([]);
    
    useEffect(() => {
      setLoading(true);
      loadData();
    }, [])
    
    async function loadData() {
      const userService = new UserService();
  
      await userService.getUsersInfo().then(async (response) => {
        console.log(response);
        if (response.data.length > 0) {
          setLoading(false);
          setUsersInfo(response.data);
        }
      });
    }

  return (
    <UserDashboardUI
        loading={loading}
        usersInfo={usersInfo}
    />
  )
}

export default UserDashboard