import axios from "axios";

import { API_BASE_URL, ACCESS_TOKEN } from "../../constants";

class TokenService {
  async getToken(username, password) {
    const url = `${API_BASE_URL}/auth/login`;
    try {
      return await axios({
        url: url,
        method: "POST",
        headers: {},
        data: {
          id: username,
          password: password,
        },
      }).then((response) => {
        if (!response.data.details) {
          if (response.data.details === "403") {
            return false;
          } else {
            localStorage.setItem(
              ACCESS_TOKEN,
              JSON.stringify(response.data.accessToken)
            );
            return true;
          }
        } 
      });
    } catch (e) {
      console.log(e);
    }
  }

  verifyToken() {
    if (
      !localStorage.getItem(ACCESS_TOKEN) ||
      JSON.parse(localStorage.getItem(ACCESS_TOKEN)) === ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  async getAgainToken() {
    localStorage.removeItem(ACCESS_TOKEN);
    return await this.getToken().then((x) => {
      console.log("Genero nuevo token: " + x);
      return x;
    });
  }
}

export { TokenService };
