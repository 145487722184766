import React from "react";
import LoadingPage from "../utils/LoadingPage/LoadingPage";
import { formatDate } from "../../utils/GeneralFunctions";
import { BsXCircle as IconDelete } from "react-icons/bs";
import LoadingModal from "../utils/LoadingModal/LoadingModal";
function CompanyDashboardUI(props) {
  const { loading, companiesInfo, deleteCompany } = props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          {!loading ? (
            <div className="card mt-5 mb-4">
              <div className="card-body">
                <div>
                  <h5 className="card-title">Dashboard Empresas</h5>
                </div>
                <hr />

                <div className="row">
                  <table className="table table-striped text-center">
                    <thead className="thead-light">
                      <tr className="text-center">
                        <th scope="col">Empresa</th>
                        <th scope="col">ID</th>
                        <th scope="col">Teléfono</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companiesInfo.map((company, index) => {
                        if (company.id !== 1) {
                          return (
                            <tr key={index}>
                              <td>{company.name}</td>
                              <td>{company.id}</td>
                              <td>{company.phone}</td>
                              <td>
                                {company.state === "A" ? "Activo" : "Inactivo"}
                              </td>
                              <td>
                                {company.license.plan.name +
                                  " - " +
                                  company.license.plan.reports +
                                  " reportes"}
                              </td>
                              <td>
                                {formatDate(company.license.creationDate)}
                              </td>
                              <td
                                className="link-danger pe-auto text-center"
                                data-bs-toggle="modal"
                                data-bs-target="#loadingModal"
                                id={company.id}
                                onClick={() => deleteCompany(company.id)}
                              >
                                <IconDelete />
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <LoadingModal
              loadingTitle={"Eliminando Empresa"}
              doneTitle={"Empresa eliminada correctamente"}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyDashboardUI;
