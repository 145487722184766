import { BASE_URL } from "../../constants";

function WelcomeUI(props) {
  const { auth } = props;

  const downloadFile = () => {
    const url = BASE_URL + "/assets/files/MANUAL.pdf"; // Cambia esto por la ruta a tu archivo en la carpeta public
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="ownContainer text-center py-5">
      <p className="fs-1">
        Bienvenid@, {auth.name} {auth.lastname}
      </p>
      <p className="fs-3">
        Al sistema de cálculo de flujo de efectivo por el método directo
      </p>
      <p>
        Con sólo cargar la base contable de tu empresa podrás obtener tus
        resultados
      </p>
      <p>Sigue estos sencillos pasos: </p>
      <p>1. DESCARGA ARCHIVO PLANTILLA:</p>
      <p>
        Ingresa a la pagina oficial: http://185.211.6.152:8095/ Desplázate hacia
        la parte superior de la pantalla hasta el menú "Descarga plantilla" y da
        clic sobre la opción “Descargar".
      </p>
      <p>2. DILIGENCIA LA INFORMACIÓN FINANCIERA: </p>
      <p>
        Una vez hayas descargado la plantilla, diligencia la información
        financiera en el formato indicado.
      </p>
      <p>3. CARGA TU ARCHIVO:</p>
      <p>
        Si has diligenciado todo el formulario (plantilla), ingresa a la opción
        "CARGA ARCHIVO" indicando el año a procesar. Antes de cargar el archivo,
        en el boton "VALIDAR" te permite realizar una validación previa de la
        información contenida en la plantilla.
      </p>
      <p>4. GENERA FLUJO DE EFECTIVO</p>
      <p>
        Una vez cargada la información de la la opcion “Flujo Efectivo” podrás
        obtener informe y gráficas de tu informacion analizada.
      </p>

      <button className="btn btn-primary" onClick={downloadFile}>DESCARGA TU MANUAL AQUI</button>
    </div>
  );
}

export { WelcomeUI };
