import { Route, Routes } from "react-router-dom";
import UploadFile from "./UploadFile/UploadFile";
import Login from "./Login/Login";
import CompanyRegister from "./CompanyRegister/CompanyRegister";
import UserRegister from "./UserRegister/UserRegister";
import useAuth from "../hooks/useAuth";
import Errors from "./Errors/Errors";
import History from "./History/History";
import FlowCash from "./FlowCash/FlowCash";
import Welcome from "./Welcome/Welcome";
import NavBar from "./NavBar/NavBar";
import FlowCashDetail from "./FlowCashDetail/FlowCashDetail";
import Charts from "./Charts/Charts";
import { URL_ROUTE } from "../constants";
import PaymentSuccessful from "./PaymentSuccessful/PaymentSuccessful";
import ShoppingCart from "./ShoppingCart/ShoppingCart";
import PaymentResponse from "./PaymentResponse/PaymentResponse";
import CompanyDashboard from "./CompanyDashboard/CompanyDashboard";
import UserDashboard from "./UserDashboard/UserDashboard";
import FlowCashDetailDocument from "./FlowCashDetailDocument/FlowCashDetailDocument";
import About from "./About/About";

function Router() {
  const { auth } = useAuth();

  return (
    <Routes>
      {/* <Route exact path="/" element = {<Navigate to={URL_ROUTE} replace/>} /> */}
      <Route path={"/"} element={<Login />} />
      <Route
        path={URL_ROUTE + "/main"}
        element={
          auth ? (
            <>
              <NavBar />
              <Welcome />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/uploadFile"}
        element={
          auth ? (
            <>
              <NavBar />
              <UploadFile />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/companyDashboard"}
        element={
          auth ? (
            <>
              <NavBar />
              <CompanyDashboard />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/userDashboard"}
        element={
          auth ? (
            <>
              <NavBar />
              <UserDashboard />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/companyRegister"}
        element={
          <>
            <NavBar />
            <CompanyRegister />
          </>
        }
      />
      <Route
        path={URL_ROUTE + "/userRegister"}
        element={
          <>
            <NavBar />
            <UserRegister />
          </>
        }
      />
      <Route
        path={URL_ROUTE + "/historyFile"}
        element={
          auth ? (
            <>
              <NavBar />
              <History />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/flowCash"}
        element={
          auth ? (
            <>
              <NavBar />
              <FlowCash />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/flowCashDetail"}
        element={
          auth ? (
            <>
              <NavBar />
              <FlowCashDetail />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/flowCashDetail/document"}
        element={
          auth ? (
            <>
              <NavBar />
              <FlowCashDetailDocument />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/charts"}
        element={
          auth ? (
            <>
              <NavBar />
              <Charts />
            </>
          ) : (
            <Errors errorType={"noLogin"} />
          )
        }
      />
      <Route
        path={URL_ROUTE + "/shoppingCart"}
        element={
          <>
            <NavBar />
            <ShoppingCart />
          </>
        }
      />
      <Route
        path={URL_ROUTE + "/paymentResponse"}
        element={
          <>
            <NavBar />
            <PaymentResponse />
          </>
        }
      />
      <Route
        path={URL_ROUTE + "/generateLicense"}
        element={
          <>
            <NavBar />
            <PaymentSuccessful />
          </>
        }
      />
      <Route
        path={URL_ROUTE + "/about"}
        element={
          <>
            <About />
          </>
        }
      />
    </Routes>
  );
}

export { Router };
