import React, { useState } from "react";
import { CompanyRegisterUI } from "./CompanyRegisterUI";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAxios from "../../hooks/useAxios";
import { modelCompany } from "../../models/CompanyModel";
import { URL_ROUTE } from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";

function CompanyRegister() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { generalRequest } = useAxios();
  const [loading, setLoading] = useState(false);
  const [mesaggeModal, setMessageModal] = useState("");
  const [successModal, setSuccessModal] = useState(false);


  const formik = useFormik({
    initialValues: initialValues(searchParams.get("license")),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      const { name, id, address, manager, phone, license } = formValue;
      try {
        setLoading(true);

        const company = {
          name,
          id,
          address,
          manager,
          phone,
          license,
        };

        await generalRequest(
          "/company",
          "POST",
          false,
          modelCompany(company)
        ).then((response) => {
          setLoading(false);

          if (response.data !== null) {
            setMessageModal("Empresa creada correctamente");
            setSuccessModal(true);
          }

          if (response.error !== null) {
            setMessageModal(
              response.error.message + ": " + response.error.details
            );
          }
        });
      } catch (error) {}
    },
  });

  function functionButtonModal() {
    if (successModal) {
      navigate(URL_ROUTE + "/userRegister?license=" + searchParams.get("license"));
    }
  }

  return (
    <CompanyRegisterUI
      formik={formik}
      initialLicense={searchParams.get("license") !== null}
      loading={loading}
      mesaggeModal={mesaggeModal}
      functionButtonModal={functionButtonModal}
    />
  );
}

function initialValues(licenseId) {
  return {
    name: "",
    id: "",
    address: "",
    manager: "",
    phone: "",
    license: licenseId !== null ? licenseId : "",
  };
}

function validationSchema() {
  return {
    name: Yup.string().required("El nombre es obligatorio"),
    id: Yup.number("Ingrese un NIT válido")
      .positive("Ingrese un NIT válido")
      .integer("Ingrese un NIT válido")
      .required("El Nit es obligatorio"),
    address: Yup.string().required("La dirección es obligatoria"),
    manager: Yup.string().required("La persona encargada es obligatoria"),
    phone: Yup.number("Ingrese un número válido")
      .integer("Ingrese un número válido")
      .positive("Ingrese un número válido")
      .required("El teléfono es obligatorio"),
    license: Yup.string().required("La licencia es obligatoria"),
  };
}

export default CompanyRegister;
