import React from "react";
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine } from "victory";
import { VictoryBoxPlot } from "victory-box-plot";
import { CASH_FLOW_ACTIVITIES } from "../../constants";

function ChartFlowStateUI(props) {
  const {
    initialBalance,
    operationActivities,
    investmentActivities,
    financingActivities,
    getTotalValue,
  } = props;

  const operationActivitiesValue = getTotalValue(
    "T",
    operationActivities,
    false
  );
  const investmentActivitiesValue = getTotalValue(
    "T",
    investmentActivities,
    false
  );
  const financingActivitiesValue = getTotalValue(
    "T",
    financingActivities,
    false
  );

  const dataChart = [
    {
      x: "Efectivo al inicio",
      y: initialBalance,
      y0: 0 ,
      value: initialBalance,
    },
    {
      x: "Operación",
      y: initialBalance + operationActivitiesValue,
      y0: initialBalance ,
      value: operationActivitiesValue,
    },
    {
      x: "Inversión",
      y: initialBalance + operationActivitiesValue + investmentActivitiesValue,
      y0: initialBalance + operationActivitiesValue,
      value: investmentActivitiesValue,
    },
    {
      x: "Financiación",
      y: initialBalance +
            operationActivitiesValue +
            investmentActivitiesValue +
            financingActivitiesValue,
      y0: initialBalance + operationActivitiesValue + investmentActivitiesValue ,
      value: financingActivitiesValue,
    },
    {
      x: "SALDO FINAL",
      y: 0,
      y0: initialBalance +
          operationActivitiesValue +
          investmentActivitiesValue +
          financingActivitiesValue,
      value:
        initialBalance +
        operationActivitiesValue +
        investmentActivitiesValue +
        financingActivitiesValue,
    },
  ];
  return (
    <div className="row justify-content-center">
      <div className="col-10">
        <VictoryChart
          style={{
            domain: { fontSize: 2 },
            parent: { border: "1px solid #ccc" },
            background: { fill: "#d0d0d0" },
          }}
          domainPadding={20}
        >
          <VictoryLabel
            text="ESTADO DE FLUJO DE EFECTIVO – METODO DIRECTO"
            style={[{ fontSize: 12 }]}
            x={225}
            y={10}
            textAnchor="middle"
          />
          <VictoryAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />
          <VictoryBar
            style={{
              data: {
                fill: ({ datum }) => {
                  for (let i = 0; i < CASH_FLOW_ACTIVITIES.length; i++) {
                    if (CASH_FLOW_ACTIVITIES[i].name === datum.x) {
                      return CASH_FLOW_ACTIVITIES[i].color;
                    }
                  }
                  return "black";
                },
              },
              parent: { border: "5px solid red" },
            }}
            barWidth={35}
            labels={({ datum }) =>
              new Intl.NumberFormat("es-CO")
                .format(datum.value / 1000000)
                .toString() + " M"
            }
            labelComponent={
              <VictoryLabel style={[{ fontSize: 5, fill: "black" }]} dx={5} />
            }
            data={dataChart}
          />
           
            {[...Array(dataChart.length-1).keys()].map((i) => (
              <VictoryLine
              style={{ data: { strokeWidth: 0.5} }}
              data={[
                { x: dataChart[i].x, y: dataChart[i].y },
                { x: dataChart[i+1].x, y: dataChart[i+1].y0 },
              ]} />
        
            ))}
            

            
           
          
        </VictoryChart>
      </div>
    </div>
  );
}

export { ChartFlowStateUI };
