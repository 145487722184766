import { createContext, useState } from "react";
import { ACCESS_TOKEN, API_BASE_URL } from "../constants";
import axios from "axios";


export const AxiosContext = createContext({
    data: undefined,
    error: undefined,
    loading: undefined,
    generalRequest: async () => {},
})

export function AxiosProvider(props){
    const {children} = props;

    const [data, setData] = useState([]);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(undefined);

    const generalRequest = async (path, type, authentication, data=null) => {
        try {

            const url = `${API_BASE_URL}${path}`;
            
            const response = await axios({
                url: url,
                method: type,
                headers: authentication ? {
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN)
                  )}`
                }  : {},
                data: data !== null ? data : {},
            });

            if(response.status === 200){
                return response.data
            }

        }catch(e){
            throw e
        }
    }

    const postRequest = async (path, authentication, data) => {
        try {

            const url = `${API_BASE_URL}${path}`;
            

            const response = await axios({
                url: url,
                method: "POST",
                headers: authentication ? {
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN)
                  )}`
                }  : {},
                data : data,
            });

            if(response.status === 200){
                return response.data
            }

        }catch(e){
            throw e
        }
    }

    const valueContext = {
        data,
        error,
        generalRequest
    };

    return(
        <AxiosContext.Provider value={valueContext}> {children}   </AxiosContext.Provider>
    );

}