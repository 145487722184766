import React, { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import FlowCashDetailDocumentUI from "./FlowCashDetailDocumentUI";
import { changeFromNumberToCurrency } from "../../utils/GeneralFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import useFlowCash from "../../hooks/useFlowCash";

function FlowCashDetailDocument() {
  const location = useLocation();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Flujo-efectivo",
    sheet: "FlujoEfectivo",
  });
  const {
    data,
    filterDataByDocumentTypeAndDocumentId
  } = useFlowCash();

  function goBack(){
    navigate(-1);
  }

  function getTotalValue(data, column) {
    const columnMap = {
      Valor_Flujo: 'Valor_Flujo',
      Debito: 'Debito',
      Credito: 'Credito'
    };
  
    let total = 0;
    
    data.forEach((item) => {
      if (columnMap[column] && item[columnMap[column]]) {
        total += parseFloat(item[columnMap[column]].replace(',','.'));
      }
    });

    return total;
  }

  return (
    <FlowCashDetailDocumentUI
      data={location.state}
      currentFormat={changeFromNumberToCurrency}
      tableRef={tableRef}
      onDownload={onDownload}
      getTotalValue={getTotalValue}
      goBack={goBack}
    />
  );
}

export default FlowCashDetailDocument;
