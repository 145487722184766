import {
  BsArrowDownCircle as IconDownload,
  BsXCircle as IconDelete,
  BsEye as IconView,
  BsBarChart as IconGraph,
  BsArrowDownSquare as IconDownload1,
} from "react-icons/bs";
import { BiBuildings } from "react-icons/bi";
import LoadingModal from "../utils/LoadingModal/LoadingModal";
import { Link } from "react-router-dom";
import { URL_ROUTE } from "../../constants";

function HistoryUI(props) {
  const {
    auth,
    files,
    downloadFileData,
    downloadFileOther,
    deleteFile,
    companiesArray,
    companySelected,
    setCompanySelected,
    loading,
    mesaggeModal,
    titleModal,
  } = props;

  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              <div>
                <h5 className="card-title">Archivos Cargados</h5>
                {auth && auth.role === "ADMIN" && (
                  <div className="row mb-3">
                    <div className="col">
                      <label htmlFor="companySelect" className="form-text">
                        Empresa
                      </label>
                      <select
                        className="form-select"
                        id="companySelect"
                        onChange={(e) => setCompanySelected(e.target.value)}
                      >
                        <option value="">Selección Empresa</option>
                        {companiesArray.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              <hr />

              <div className="row">
                <table className="table table-striped text-center">
                  <thead className="thead-light">
                    <tr className="text-center">
                      <th scope="col">Año</th>
                      <th scope="col">Archivo</th>
                      <th scope="col">Fecha de Carga</th>
                      <th scope="col">Usuario</th>
                      <th scope="col">Informe</th>
                      <th scope="col">Gráficas</th>
                      {auth && auth.role === "ADMIN" && (
                        <>
                          <th scope="col">Descargar FE</th>
                          <th scope="col">Descargar Otras</th>
                          <th scope="col">Eliminar</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index) => {
                      return (
                        <tr key={index}>
                          <td>{file.year}</td>
                          <td>{file.name}</td>
                          <td>{file.uploadDate}</td>
                          <td>{file.user.name}</td>
                          <td>
                            <Link
                              className="nav-link link-success text-center"
                              to={`${URL_ROUTE}/flowCash?id=${file.id}&initialBalance=${file.initialBalance}`}
                            >
                              <IconView />
                            </Link>
                          </td>
                          <td>
                            <Link
                              className="nav-link link-warning text-center"
                              to={`${URL_ROUTE}/charts?id=${file.id}&initialBalance=${file.initialBalance}&year=${file.year}`}
                            >
                              <IconGraph />
                            </Link>
                          </td>
                          {auth && auth.role === "ADMIN" && (
                            <>
                              <td
                                className="link-primary pe-auto text-center"
                                data-bs-toggle="modal"
                                data-bs-target="#loadingModal"
                              >
                                <IconDownload
                                  id={file.id}
                                  onClick={downloadFileData}
                                />
                              </td>
                              <td
                                className="link-success pe-auto text-center"
                                data-bs-toggle="modal"
                                data-bs-target="#loadingModal"
                              >
                                <IconDownload1
                                  id={file.id}
                                  onClick={downloadFileOther}
                                />
                              </td>
                              <td
                                className="link-danger pe-auto text-center"
                                data-bs-toggle="modal"
                                data-bs-target="#loadingModal"
                                onClick={deleteFile}
                              >
                                <IconDelete id={file.id} onClick={deleteFile} />
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {auth &&
                  auth.role === "ADMIN" &&
                  companySelected === undefined && (
                    <div className="my-5 py-3 text-center">
                      <BiBuildings size="5rem" color="gray" />
                      <p>Selecciona una empresa</p>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <LoadingModal
            loadingTitle={titleModal}
            doneTitle={mesaggeModal}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export { HistoryUI };
