import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { URL_ROUTE } from "../../constants";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { PaymentSuccessfulUI } from "./PaymentSuccessfulUI";
import { modelLicense } from "../../models/LicenseModel";

function PaymentSuccessful() {
  const location = useLocation();

  const [licenseData, setLicenseData] = useState({id:""});
  const navigate = useNavigate();
  const { generalRequest } = useAxios();
  const [loading, setLoading] = useState(true);
  const [mesagge, setMessage] = useState(" ");
 

  useEffect(() => {
    generateLicense();
  }, []);

  async function generateLicense() {
    const license = location.state;

    await generalRequest("/license", "POST", false, modelLicense(license)).then(
      (response) => {
        console.log(response);
        if (response.data.length > 0) {
          setMessage("Licencia correctamente: " + response.data[0].id);
          setLicenseData((e)=>e={ id: response.data[0].id, email : location.state.email });
          setLoading(false);
        }

        if (response.error) {
          setMessage(
            response.error.message + ": " + response.error.details
          );
          setLoading(false);
        }
      }
    );
  }

  function goToRegister() {

      navigate(URL_ROUTE + "/companyRegister?license=" + licenseData.id);
  }
  return <PaymentSuccessfulUI loading={loading} mesagge={mesagge} licenseData={licenseData} goToRegister={goToRegister}/>;
}

export default PaymentSuccessful;
