import React from "react";
import LoadingPage from "../utils/LoadingPage/LoadingPage";
import { BsCartX } from "react-icons/bs";

function PaymentResponseUI(props) {
  const { loading, goToShoppingCar } = props;

  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              {loading ? (
                <LoadingPage loadingTitle={"Cargando..."} />
              ) : (
                <div className="text-center">
                   <div className="mb-4 py-3">
                      <BsCartX size="5rem" color="red" />
                    </div>
                  <p>Error en procesar tu pago, por favor intenta de nuevo</p>
                  <button
                    className="btn btn-primary w-25"
                    onClick={goToShoppingCar}
                  >
                    Regresar a la compra
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentResponseUI;
