import React, { useEffect } from "react";
import ShoppingCartUI from "./ShoppingCartUI";
import { modelPaymentRequestData } from "../../models/PaymentRequestDataModel";
import useAxios from "../../hooks/useAxios";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { PAYMENT_DETAILS } from "../../constants";
import { modelPlansArray } from "../../models/PlanModel";
import { changeFromCurrencyToNumber, changeFromNumberToCurrency } from "../../utils/GeneralFunctions";

function ShoppingCart() {
  const { generalRequest } = useAxios();
  const handler = useRef();
  const [planArray, setPlanArray] = useState([]);

  useEffect(() => {
    loadPlans();

    if (typeof window.ePayco.checkout.configure === "function") {
      handler.current = window.ePayco.checkout.configure({
        key: PAYMENT_DETAILS.key,
        test: PAYMENT_DETAILS.test,
      });
    }
  }, []);

  async function loadPlans() {
    await generalRequest("/plan", "GET", false).then(async (response) => {
      await modelPlansArray(response.data).then((response) => {
        setPlanArray(response);
      });
    });
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      const { plan, price, name, email, typeDoc, id, phone, address } =
        formValue;
      try {
        const client = {
          name,
          address,
          email,
          typeDoc,
          phone,
          id,
        };
        const data = modelPaymentRequestData(plan, client, changeFromCurrencyToNumber(price).toString());

        console.log("Pagando");
        handler.current.open(data);
      } catch (error) {}
    },
  });

  function planChange(planId) {
    const price = planArray.find((plan) => plan.id === planId).price;
    formik.setFieldValue("price", changeFromNumberToCurrency(price));
  }

  return (
    <>
      <ShoppingCartUI
        formik={formik}
        planArray={planArray}
        planChange={planChange}
      />
    </>
  );
}

function initialValues() {
  return {};
}

function validationSchema() {
  return {
    name: Yup.string().required("El nombre es obligatorio"),
    email: Yup.string()
      .email("Ingrese un correo válido")
      .required("El username es obligatorio"),
    typeDoc: Yup.string().required("El tipo de documento es obligatorio"),
    id: Yup.number("Ingrese un número de identificación válido")
      .integer("Ingrese un número de identificación válido")
      .positive("Ingrese un número de identificación válido")
      .required("El número de identificación es obligatorio"),
    phone: Yup.number("Ingrese un teléfono válido")
      .integer("Ingrese un teléfono válido")
      .positive("Ingrese un teléfono válido")
      .required("El teléfono es obligatorio"),
    address: Yup.string().required("La dirección es obligatoria"),
    price: Yup.string().required("El monto es obligatorio"),
    plan: Yup.string().required("El plan es obligatorio"),
  };
}

export default ShoppingCart;
