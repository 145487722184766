import React from 'react'
import { formatDate } from '../../utils/GeneralFunctions';
import LoadingPage from '../utils/LoadingPage/LoadingPage';

function UserDashboardUI(props) {

    const { loading, usersInfo } = props;
    return (
      <div className="ownContainer">
        <div className="row justify-content-center">
          <div className="col-10">
            {
              !loading ? (
                <div className="card mt-5 mb-4">
                <div className="card-body">
                  <div>
                    <h5 className="card-title">Dashboard Usuarios</h5>
                  </div>
                  <hr />
    
                  <div className="row">
                    <table className="table table-striped text-center">
                      <thead className="thead-light">
                        <tr className="text-center">
                          <th scope="col">Empresa</th>
                          <th scope="col">Email</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Teléfono</th>
                          <th scope="col">Estado</th>
                          <th scope="col">Rol</th>
                          <th scope="col">Fecha</th>
                        </tr>
                      </thead>
                      <tbody>
                        {usersInfo.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{user.company.name}</td>
                              <td>{user.id}</td>
                              <td>{user.name}</td>
                              <td>{user.phone}</td>
                              <td>{user.state === 'A' ? 'Activo' : 'Inactivo'}</td>
                              <td>{user.role}</td>
                              <td>{formatDate(user.creationDate)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
              ) : (
                <LoadingPage loadingTitle={"Cargando dashboard..."} />
              )
            }
            
            
          </div>
        </div>
      </div>
  )
}

export default UserDashboardUI