import React from "react";
import { BASE_URL } from "../../constants";

import useAuth from "../../hooks/useAuth";

import { NavBarUI } from "./NavBarUI";

function NavBar() {
  const { auth, signOut } = useAuth();

  const downloadFile = () => {
    const url = BASE_URL + "/assets/files/PLANTILLA_BASE_CONTABLE.xls"; // Cambia esto por la ruta a tu archivo en la carpeta public
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return <NavBarUI auth={auth} signOut={signOut} downloadFile={downloadFile}/>;
}

export default NavBar;
