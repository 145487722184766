import axios from "axios";

import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

class CompanyService {
  async getCompanies() {
    const url = API_BASE_URL + "/company";

    try {
      const responseCompanies = await axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
      });

      if (responseCompanies.status === 200) {
        return responseCompanies.data.map((company) => modelCompany(company));
      }
      return null;
    } catch (e) {
      throw "Error en consulta con el servidor";
    }
  }

  async createCompany(company) {
    const url = `${API_BASE_URL}/company`;
    const newCompany = modelCompany(company);

    try {
      const response = await axios({
        url: url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
        data: newCompany,
      });
      if (response.status === 200) {
        return modelCompany(response.data);
      }
      return null;
    } catch (e) {
      throw "Error en consulta con el servidor";
    }
  }

  async getCompaniesInfo() {
    const url = `${API_BASE_URL}/company/info`;
    try {
      const response = await axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (e) {
      throw "Error en consulta con el servidor";
    }
  }

  async deleteCompany(companyId) {
    const url = API_BASE_URL + "/company/" + companyId;

    try {
      const responseCompanies = await axios({
        url: url,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
      });

      if (responseCompanies.status === 200) {
        return responseCompanies.data.data[0];
      }
      return null;
    } catch (e) {
      throw "Error en eliminar compañía";
    }
  }
}
function modelCompany(company) {
  return {
    id: company.id,
    name: company.name,
    address: company.address,
    manager: company.manager,
    phone: company.phone,
    state: company.state ? company.state : "A",
    license: company.license,
  };
}

export { CompanyService };
