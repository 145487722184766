import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataService } from "../../services/DataService";
import { NiifService } from "../../services/NiifService";
import { ChartsUI } from "./ChartsUI";
import useFlowCash from "../../hooks/useFlowCash";
import { URL_ROUTE } from "../../constants";

function Charts() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { data, setData, fileId, setFileId, getTotalMonthValueByMovementType } =
    useFlowCash();

  const [operationActivitiesCharge, setOperationActivitiesCharge] = useState(
    []
  );
  const [operationActivitiesPayment, setOperationActivitiesPayment] = useState(
    []
  );
  const [operationActivities, setOperationActivities] = useState([]);
  const [investmentActivities, setInvestmentActivities] = useState([]);
  const [financingActivities, setFinancingActivities] = useState([]);

  const initialBalance = Number(searchParams.get("initialBalance"));
  const year = Number(searchParams.get("year"));

  //Function to get the data from API
  async function loadData(id) {
    const dataService = new DataService();

    await dataService.getData(id).then(async (response) => {
      console.log(response.data);
      if (response.status === 200) {
        setLoading(false);
        setData(response.data);
      }
    });
  }

  async function loadNiif() {
    const niifService = new NiifService();

    await niifService.getNiifs().then(async (response) => {
      console.log(response);
      const niifsArray = response;
      setOperationActivities(
        niifsArray.filter(
          (niif) => niif.activity.id === 1 && niif.classification === null
        )
      );
      setOperationActivitiesCharge(
        niifsArray.filter(
          (niif) => niif.activity.id === 1 && niif.classification === "C"
        )
      );
      setOperationActivitiesPayment(
        niifsArray.filter(
          (niif) => niif.activity.id === 1 && niif.classification === "P"
        )
      );
      setInvestmentActivities(
        niifsArray.filter((niif) => niif.activity.id === 2)
      );
      setFinancingActivities(
        niifsArray.filter((niif) => niif.activity.id === 3)
      );
    });
  }

  function getTotalValue(month, dataArray, formatted, typeMovement = "A") {
    const niifArray = [];
    dataArray.forEach((data) => niifArray.push(data.id));
    return getCellValue(month, niifArray.flat(), formatted, typeMovement);
  }

  function getCellValue(month, niifId, formatted, typeMovement = "A") {
    if (niifId === []) {
      return 0;
    }

    if (data !== []) {
      let cellValue = 0;

      filterData(month, niifId).forEach((item) => {
        if (typeMovement === "A" || typeMovement === item.movementType) {
          cellValue += item.flowValue;
        }
      });

      if (formatted) {
        return new Intl.NumberFormat("es-CO").format(cellValue);
      } else {
        return cellValue;
      }
    }
  }

  function filterData(month, niifId) {
    const dataFiltered = data.filter((item) => {
      if (month === "T") {
        return item.niif ? niifId.includes(item.niif.id) : false;
      } else {
        return item.niif
          ? niifId.includes(item.niif.id) && item.date.getMonth() === month
          : false;
      }
    });

    return dataFiltered;
  }

  function calculateMonthValues(month) {
    //   const monthValuesArray = [];
    //   for (let i = 0; i < 12; i++) {
    //     monthValuesArray.push(getTotalValue(i, [
    //       ...operationActivitiesCharge,
    //       ...operationActivitiesPayment,
    //       ...operationActivities,
    //       ...investmentActivities,
    //       ...financingActivities,
    //     ],false))
    //   }
    //  // console.log("2");
    //   setTotalMonthValues(monthValuesArray);

    return getTotalValue(
      month,
      [
        ...operationActivitiesCharge,
        ...operationActivitiesPayment,
        ...operationActivities,
        ...investmentActivities,
        ...financingActivities,
      ],
      false
    );
  }

  function getInitialBalance(month, formatted) {
    const initialBalance = Number(searchParams.get("initialBalance"));

    if (month === "T") {
      if (formatted) {
        return new Intl.NumberFormat("es-CO").format(initialBalance);
      } else {
        return initialBalance;
      }
    }

    let value = initialBalance;
    for (let i = 0; i < month; i++) {
      value += calculateMonthValues(i);
    }

    if (formatted) {
      return new Intl.NumberFormat("es-CO").format(value);
    } else {
      return value;
    }
  }

  function getTotalFlowCash(month, formatted) {
    const monthInitialBalance = getInitialBalance(month, false);

    const monthFlowCash = getTotalValue(
      month,
      [
        ...operationActivitiesCharge,
        ...operationActivitiesPayment,
        ...operationActivities,
        ...investmentActivities,
        ...financingActivities,
      ],
      false
    );

    const value = monthInitialBalance + monthFlowCash;

    if (formatted) {
      return new Intl.NumberFormat("es-CO").format(value);
    } else {
      return value;
    }
  }

  function goToFlowCash(){
    const id = searchParams.get("id");
    navigate(`${URL_ROUTE}/flowCash?id=${id}&initialBalance=${initialBalance}`);
  }

  useEffect(() => {
    const id = searchParams.get("id");
    
    if (id !== fileId) {
      console.log("Cargando datos");
      setFileId(id);
      setLoading(true);
      loadData(id);
    }
    loadNiif();
  }, []);

  return (
    <ChartsUI
      initialBalance={initialBalance}
      operationActivities={[
        ...operationActivitiesCharge,
        ...operationActivitiesPayment,
        ...operationActivities,
      ]}
      investmentActivities={investmentActivities}
      financingActivities={financingActivities}
      getTotalValue={getTotalValue}
      getTotalFlowCash={getTotalFlowCash}
      year={year}
      getTotalMonthValueByMovementType={getTotalMonthValueByMovementType}
      loading={loading}
      goToFlowCash={goToFlowCash}
    />
  );
}

export default Charts;