import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";
import { LoginUI } from "./LoginUI";
import { useNavigate } from "react-router-dom";
import { URL_ROUTE } from "../../constants";

function Login() {
  const navigate = useNavigate();

  const { signIn, auth } = useAuth();
  const [login, setLogin] = useState(false);

  useEffect(() => {
    if (login && auth !== undefined) {
      navigate(URL_ROUTE + "/main");
    }
  }, [auth]);

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      const { username, password } = formValue;
      try {
        await signIn(username, password).then((response) => {
          setLogin(response);
          if(!response){
            alert("Usuario y/o contraseña no válidos")
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return <LoginUI formik={formik} />;
}

function validationSchema() {
  return {
    username: Yup.string().required("El usuario es obligatorio"),
    password: Yup.string().required("La contraseña es obligatoria"),
  };
}

function initialValues() {
  return {
    //username: "Admin",
    //password: "password",
     username: "",
     password: "",
  };
}

export default Login;
