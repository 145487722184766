import * as XLSX from "xlsx";

class FileModel {
  async fileArrayToModelCSV(data) {
    const responseArray = [];
    data.forEach((element, index) => {
      responseArray.push({
        account: parseInt(element.Cuenta),
        accountName: element["Nombre de la cuenta"],
        thirdParty: element.Nit,
        thirdPartyName: element["Nombre del tercero"],
        date: this.formatDate(element.Fecha),
        documentType:
          element.Documento === "S001" ? "S001" : element["Tipo Doc"],
        document:
          element.Documento === "S001" ? 0 : parseInt(element.Documento),
        details: element.Detalle,
        initialBalance: this.formatValue(element["Saldo Inicial"]),
        debit: this.formatValue(element.Debito),
        credit: this.formatValue(element.Credito),
        row: index + 1,
      });
    });

    return responseArray;
  }

  async fileArrayToModelExcel(data) {
    const responseArray = [];

    data.forEach((element) => {
      const elementDate = XLSX.SSF.parse_date_code(element.Fecha);
      responseArray.push({
        account: element.Cuenta,
        accountName: element["Nombre de la cuenta"],
        thirdParty: element.Nit,
        thirdPartyName: element["Nombre del tercero"],
        date: new Date(elementDate.y, elementDate.m - 1, elementDate.d),
        documentType:
          element.Documento === "S001" ? "S001" : element["Tipo Doc"],
        document:
          element.Documento === "S001" ? 0 : parseInt(element.Documento),
        details: element.Detalle,
        initialBalance: element["Saldo Inicial"],
        debit: element.Debito,
        credit: element.Credito,
        row: element.__rowNum__ + 1,
      });
    });

    return responseArray;
  }

  formatValue(value) {
    const withoutDots = value.replace(/\./g, ""); // Elimina los puntos
    const withDots = withoutDots.replace(/,/g, "."); // Cambia las comas por puntos

    return parseFloat(withDots);
  }

  formatDate(date) {
    const months = {
      ene: 0,
      feb: 1,
      mar: 2,
      abr: 3,
      may: 4,
      jun: 5,
      jul: 6,
      ago: 7,
      sep: 8,
      oct: 9,
      nov: 10,
      dic: 11,
    };

    const parts = date.split(",");
    const day = parseInt(parts[0], 10);
    let month;
    if (isNaN(parts[1])) {
      month = months[parts[1].toLowerCase()];
    } else {
      month = parseInt(parts[1]) - 1;
    }
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  }

  async fileExportModel(data) {
    const responseArray = [];

    data.forEach((element) =>
      responseArray.push({
        Cuenta: element.account,
        Nombre_de_la_cuenta: element.accountName,
        Nit: element.thirdParty,
        Nombre_del_tercero: element.thirdPartyName,
        Fecha: element.date.toLocaleDateString("es-CO"),
        Tipo_Doc: element.documentType ?? "NA",
        Documento: element.document,
        Detalle: element.details,
        Debito: element.debit.toString().replace(".", ","),
        Credito: element.credit.toString().replace(".", ","),
        Valor_Neto: element.netValue.toString().replace(".", ","),
        Valor_Flujo: element.flowValue.toString().replace(".", ","),
        Documento_de_Efectivo:
          element.cashDocument === null ? "NA" : element.cashDocument,
        Tipo_de_Movimiento:
          element.movementType === "E"
            ? "Entrada"
            : element.movementType === "S"
            ? "Salida"
            : "Salida/Entrada",
        Taxonomia_Niif: element.niif.name,
        Mes: element.date.getMonth() + 1,
      })
    );

    return responseArray;
  }

  validateElement(element, year) {
    if (typeof element.account !== "number") {
      throw "La cuenta " + element.account + " no es un número";
    }
    if (typeof element.document !== "number") {
      throw "El número de documento " + element.document + " no es un número";
    }
    if (typeof element.initialBalance !== "number") {
      throw "El saldo inicial " + element.initialBalance + " no es un número";
    }
    if (typeof element.debit !== "number") {
      throw "El valor del débito " + element.debit + " no es un número";
    }
    if (typeof element.credit !== "number") {
      throw "El valor del crédito " + element.credit + " no es un número";
    }
    if (element.date.getFullYear() !== parseInt(year)) {
      throw "La fecha del movimiento no corresponde al año ingresado";
    }

    return true;
  }
}

export { FileModel };
