import { useState, createContext } from "react";
import {  ACCESS_TOKEN, URL_ROUTE } from "../constants";
import { useNavigate } from "react-router-dom";

import { TokenService } from "../services/ApiServices/TokenService";
import { UserService } from "../services/UserService";

export const AuthContext = createContext({
  auth: undefined,
  setAuth: () => {},
  signIn: () => {},
  signOut: () => {},
});

export function AuthProvider(props) {
  const { children } = props;

  const [auth, setAuth] = useState(undefined);

  const navigate = useNavigate();

  const signIn = async (username, password) => {
    try {
      const tokenService = new TokenService();
      return await tokenService.getToken(username, password).then(async (response) => {
        if(response){
          
          const userService = new UserService();
          return await userService.getUserInfo(username).then( (response) => {
            setAuth(modelUserAuth(response));
            return true;
          });
        }else{
          return false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const modelUserAuth = (user) => {
    return {
      id: user.id,
      name: user.name,
      lastname: user.lastname,
      company: user.company,
      role: user.role,
    };
  };

  const signOut = () => {
    try {
      localStorage.removeItem(ACCESS_TOKEN);
      setAuth();
      navigate(URL_ROUTE);
    } catch (error) {
      throw error;
    }
  };

  const valueContext = {
    auth,
    setAuth,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={valueContext}>{children}</AuthContext.Provider>
  );
}
