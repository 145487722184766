import axios from "axios";

import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

import { TokenService } from "./ApiServices/TokenService";

class UserService {

  async createUser(user) {
    const url = `${API_BASE_URL}/user`;
    const newUser = modelUser(user);

    try {
      const response = await axios({
        url: url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
        data: newUser,
      });
      if (response.status === 200) {
        return modelUser(response.data);
      }
      return null;
    } catch (e) {
      throw "Error en consulta con el servidor";
    }
  }
  
  async getUserInfo(userId) {
    const tokenService = new TokenService();
    const url = API_BASE_URL + "/private/user/" + userId;
    if(tokenService.verifyToken()){
      try {
        //Sentencia HTTP
        const response = await axios({
          url: url,
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem(ACCESS_TOKEN)
              )}`,
          },
        });
        if(response.status === 200){
          return modelUser(response.data);
        }
        return null;

      } catch (e) {
        throw ("Error en consulta con el servidor")
      }
    } 
  }

  async getUsersInfo(){
    const url = `${API_BASE_URL}/private/users`;
    try {
      const response = await axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (e) {
      throw "Error en consulta con el servidor";
    }
  }
}

function modelUser(user) {
  return {
    id: user.id,
    name: user.name,
    lastname: user.lastname,
    password: user.password,
    phone: user.phone,
    company: user.company,
    role: user.role ? user.role : "USER",
    state: user.state ? user.state : "A" 
  };
}

export { UserService };
