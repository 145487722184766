export const API_BASE_URL = "http://www.quepasoconeldinero.com:8095/api";
//export const API_BASE_URL = "http://localhost:8095/api";
export const BASE_URL = "http://www.quepasoconeldinero.com:8095";
//export const BASE_URL = "http://localhost:3000";

export const URL_COMPLEMENT = "";
export const URL_ROUTE = "" + URL_COMPLEMENT;
export const URL_COMPLETE = BASE_URL + URL_COMPLEMENT;

export const USER_AUTHENTICATED = "Username";
export const ACCESS_TOKEN = "token";
export const DATA = "data";

export const LICENSE_LENGTH = 25;

export const ERROR_MENSSAGES = {
  LOGIN_AGAIN: "Sesión Finalizada, Vuelve a iniciar sesión",
};

export const MONTHS = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

export const CASH_FLOW_ACTIVITIES = [
  { name: "Efectivo al inicio", color: "#ffff2e" },
  { name: "Operación", color: "#f96ade" },
  { name: "Inversión", color: "#29d5ff" },
  { name: "Financiación", color: "#3cee80" },
  { name: "SALDO FINAL", color: "#ffff2e" },
];

export const PAYMENT_DETAILS = {
  key: "d255064782f0244f87ae382066da5941",
  test: true,
};

// export const OPERATIONS_ACTIVITIES_ROWS = {
//   rowsCharge: [
//     {
//       niifId: [2],
//       name: "Cobros procedentes de las ventas de bienes y prestación de servicios",
//     },
//     {
//       niifId: [],
//       name: "Cobros procedentes de regalias, cuotas, comisiones y otros ingresos de actividades ordinarias",
//     },
//     {
//       niifId: [],
//       name: "Cobros derivados de contratos mantenidos para intermediacion o para negociar con ellos",
//     },
//     {
//       niifId: [],
//       name: "Cobros procedentes de primas y prestaciones, anualidades y otros beneficios de polizas suscritas",
//     },
//     {
//       niifId: [20],
//       name: "Otros cobros por actividades de operación",
//     },
//   ],

//   rowsPayment: [
//     {
//       niifId: [24],
//       name: "Pagos a proveedores por el suministro de bienes y servicios",
//     },
//     {
//       niifId: [],
//       name: "Pagos procedentes de contratos mantenidos para intermediacion o para negociar",
//     },
//     {
//       niifId: [25],
//       name: "Pagos a y por cuenta de los empleados",
//     },
//     {
//       niifId: [],
//       name: "Pagos por primas y prestaciones, anualidades y otras obligaciones derivadas de las polizas suscritas",
//     },
//     {
//       niifId: [23],
//       name: "Otros pagos por actividades de operación",
//     },
//   ],
//   otherRows: [
//     {
//       niifId: [7],
//       name: "Dividendos pagados AO",
//     },
//     {
//       niifId: [8],
//       name: "Dividendos recibidos AO",
//     },
//     {
//       niifId: [17],
//       name: "Intereses pagados",
//     },
//     {
//       niifId: [18],
//       name: "Intereses recibidos",
//     },
//     {
//       niifId: [16],
//       name: "Impuestos a las ganancias reembolsados (pagados)",
//     },
//     {
//       niifId: [19],
//       name: "Otras entradas (salidas) de efectivo",
//     },
//   ],
// };
