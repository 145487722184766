import LoadingPage from "../utils/LoadingPage/LoadingPage";
import { BsCartCheck } from "react-icons/bs";



function PaymentSuccessfulUI(props) {
  const { loading, mesagge, goToRegister } = props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              {loading ? (
                <LoadingPage loadingTitle={"Generando Licencia..."} />
              ) : (
                <div className="text-center">
                   <div className="mb-4 py-3">
                      <BsCartCheck size="5rem" color="green" />
                    </div>
                  <p>{mesagge}</p>
                  <button
                    className="btn btn-primary w-25"
                    onClick={goToRegister}
                  >
                    Registrar empresa
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PaymentSuccessfulUI };
