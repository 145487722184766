import React from "react";

function FlowCashDetailDocumentUI(props) {
  const { data, currentFormat, getTotalValue, goBack, onDownload, tableRef } = props;

  return (
    <>
      <div className="row justify-content-start bg-light gx-0 ps-1">
        <div className="col-2 py-1">
          <button className="btn btn-primary w-100" onClick={goBack}>
            Atrás
          </button>
        </div>
      </div>
      <table
        className="table table-warning table-striped table-bordered text"
        ref={tableRef}
        style={{ width: "100%" }}
      >
        <thead className="subtitle sticky-top">
          <tr>
            <th colSpan={8}>Total</th>
            <th className="text-nowrap text-end">
              {currentFormat(getTotalValue(data, "Debito"))}
            </th>
            <th className="text-nowrap text-end">
              {currentFormat(getTotalValue(data, "Credito"))}
            </th>
            <th className="text-nowrap text-end">
              {currentFormat(getTotalValue(data, "Valor_Flujo"))}
            </th>
            <th colSpan={3}> </th>
          </tr>
          <tr>
            <th>Cuenta</th>
            <th>Nombre</th>
            <th>Nit</th>
            <th>Nombre tercero</th>
            <th>Fecha</th>
            <th>Tipo Documento</th>
            <th>Documento</th>
            <th>Detalle</th>
            <th>Débito</th>
            <th>Crédito</th>
            <th>Valor Flujo</th>
            <th>Documento de Efectivo</th>
            <th>Tipo de Movimiento</th>
            <th>Taxonomía NIIF</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              item.Valor_Neto !== 0 && (
                <tr>
                  <td scope="row" className="text-nowrap">
                    {item.Cuenta}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Nombre_de_la_cuenta}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Nit}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Nombre_del_tercero}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Fecha}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Tipo_Doc}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Documento}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Detalle}
                  </td>
                  <td scope="row" className="text-nowrap text-end">
                    {currentFormat(item.Debito.replace(",", "."))}
                  </td>
                  <td scope="row" className="text-nowrap text-end">
                    {currentFormat(item.Credito.replace(",", "."))}
                  </td>
                  <td
                    scope="row"
                    className="text-nowrap subtitle text-end fw-bold"
                    style={{
                      color:
                        parseFloat(item.Valor_Flujo.replace(",", ".")) < 0
                          ? "red"
                          : "black",
                    }}
                  >
                    {currentFormat(item.Valor_Flujo.replace(",", "."))}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Documento_de_Efectivo}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Tipo_de_Movimiento}
                  </td>
                  <td scope="row" className="text-nowrap">
                    {item.Taxonomia_Niif}
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </table>
      <div className="text-center">
        <button className="btn btn-primary w-50" onClick={onDownload}>
          Exportar Archivo
        </button>
      </div>
    </>
  );
}

export default FlowCashDetailDocumentUI;
