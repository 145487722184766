import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { UserRegisterUI } from "./UserRegisterUI";
import useAxios from "../../hooks/useAxios";
import { modelUser } from "../../models/UserModel";
import { URL_ROUTE } from "../../constants";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";


function UserRegister() {

  const {generalRequest} = useAxios();
  const navigate = useNavigate();
  const {auth} = useAuth();
  const [searchParams] = useSearchParams();
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mesaggeModal, setMessageModal] = useState("");
  const [successModal, setSuccessModal] = useState(false);
 

  async function loadCompanies() {
    await generalRequest("/company","GET",false).then((response)=>{
      setCompanies(response.data)
    })
  }

  useEffect(() => {
    if(auth ===undefined){
      loadCompanies();
    } else {
      auth.role === "ADMIN"  ? loadCompanies() : setCompanies([auth.company]);
    }
    
    if(searchParams.get("license") !== null){
      loadLicense();
    }
  }, []);

  async function loadLicense(){
    await generalRequest("/license/" + searchParams.get("license"), "GET", false).then((response) => {
      console.log(response);
      if(response.data.length > 0)
      formik.setFieldValue("id", response.data[0].email)
    })
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      const { id, password, name, lastname, phone, company } = formValue;
      try {
        setLoading(true);

          const user = {
            id,
            password,
            name,
            lastname,
            phone,
            company,
          }

          await generalRequest("/user","POST",false,modelUser(user)).then((response)=>{
            setLoading(false);
  
            if (response.data !== null) {
              setMessageModal("Usuario creado correctamente");
              setSuccessModal(true);
            }
  
            if (response.error !== null) {
              setMessageModal(
                response.error.message + ": " + response.error.details
              );
            }
          });
      } catch (error) {}
    },
  });

  function functionButtonModal() {
    if (successModal) {
      navigate(URL_ROUTE+ "/");
    }
  }

  return (
    <div>
      <UserRegisterUI
        formik={formik}
        companiesArray={companies}
        initialEmail={searchParams.get("license") !== null}
        loading={loading}
        mesaggeModal={mesaggeModal}
      functionButtonModal={functionButtonModal}
      />
    </div>
  );
}

function initialValues() {
  return {
    id: "",
    password: "",
    passwordConfirmation: "",
    name: "",
    lastname: "",
    phone: "",
    company: "",
  };
}

function validationSchema() {
  return {
    id: Yup.string().email("Ingrese un correo válido").required("El username es obligatorio"),
    password: Yup.string()
      .required("Campo obligatorio")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Debe contener mínimo 8 carácteres, una mayúscula, una minúscula y  un número"
      ),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "La contraseña debe coincidir"
    ),
    name: Yup.string().required("El nombre es obligatorio"),
    lastname: Yup.string().required("El apellido es obligatorio"),
    phone: Yup.number("Ingrese un número válido")
      .integer("Ingrese un número válido")
      .positive("Ingrese un número válido")
      .required("El teléfono es obligatorio"),
    company: Yup.number().required("La empresa es obligatoria"),
  };
}

export default UserRegister;
