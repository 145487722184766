import { BrowserRouter } from "react-router-dom";

import { Router } from "./components/Router";
import { AuthProvider } from "./context/AuthContext";
import { FlowCashProvider } from "./context/FlowCashContext";
import { DataProvider } from "./context/DataContext";
import { AxiosProvider } from "./context/AxiosContext";

function App() {
  return (
    <div className="App">
      <div className="container">
        <BrowserRouter>
          <AuthProvider>
            <AxiosProvider>
              <DataProvider>
                <FlowCashProvider>
                  <Router />
                </FlowCashProvider>
              </DataProvider>
            </AxiosProvider>
          </AuthProvider>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
