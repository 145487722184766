import React from "react";
import { ChartCashBalanceUI } from "../ChartCashBalance/ChartCashBalanceUI";
import { ChartFlowStateUI } from "../ChartFlowState/ChartFlowStateUI";
import { ChartIOUI } from "../ChartIO/ChartIOUI";
import LoadingPage from "../utils/LoadingPage/LoadingPage";

function ChartsUI(props) {
  const {
    initialBalance,
    operationActivities,
    investmentActivities,
    financingActivities,
    getTotalValue,
    getTotalFlowCash,
    year,
    getTotalMonthValueByMovementType,
    loading,
    goToFlowCash,
  } = props;

  return (
    <>
      {!loading ? (
        <div className="card text-center">
          <div className="card-header">
            <div className="row justify-content-end">
              <div className="col-3">
                <button className="btn btn-primary w-100" onClick={goToFlowCash}>
                  Flujo de Efectivo
                </button>
              </div>
            </div>
            <ul className="nav nav-tabs card-header-tabs" id="myTab">
              <li className="nav-item">
                <a
                  href="#flowState"
                  className="nav-link active"
                  data-bs-toggle="tab"
                >
                  Estado de Flujo
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#cashBalance"
                  className="nav-link"
                  data-bs-toggle="tab"
                >
                  Saldos de efectivo
                </a>
              </li>
              <li className="nav-item">
                <a href="#IO" className="nav-link" data-bs-toggle="tab">
                  Entrada vs Salida
                </a>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-pane fade show active" id="flowState">
                <ChartFlowStateUI
                  initialBalance={initialBalance}
                  operationActivities={operationActivities}
                  investmentActivities={investmentActivities}
                  financingActivities={financingActivities}
                  getTotalValue={getTotalValue}
                />
              </div>
              <div className="tab-pane fade" id="cashBalance">
                <ChartCashBalanceUI
                  initialBalance={initialBalance}
                  getTotalFlowCash={getTotalFlowCash}
                  year={year}
                />
              </div>
              <div className="tab-pane fade" id="IO">
                <ChartIOUI
                  initialBalance={initialBalance}
                  getTotalFlowCash={getTotalFlowCash}
                  getTotalMonthValueByMovementType={
                    getTotalMonthValueByMovementType
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingPage loadingTitle={"Cargando información..."} />
      )}
    </>
  );
}

export { ChartsUI };
