import axios from "axios";

import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

import { TokenService } from "./ApiServices/TokenService";

class FileService {
  async getFilesByCompany(companyId) {
    const tokenService = new TokenService();
    const url = API_BASE_URL + "/private/file/company/" + companyId;
    if (tokenService.verifyToken()) {
      try {
        //Sentencia HTTP
        const response = await axios({
          url,
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem(ACCESS_TOKEN)
            )}`,
          },
        });

        if (response.status === 200) {
          return response.data.map((item) => modelFile(item));
        } else {
          return false;
        }
      } catch (e) {
        console.log("Error autenticando, " + e.response.status);
      }
    }
  }

  async deleteFileById(id) {
    const tokenService = new TokenService();
    const url = API_BASE_URL + "/private/file/" + id;
    if (tokenService.verifyToken()) {
      try {
        //Sentencia HTTP
        console.log("Eliminadnd");
        const response = await axios({
          url,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem(ACCESS_TOKEN)
            )}`,
          },
        });

        if (response.status === 200) {
          return {
            status: response.status,
            message: "Archivo eliminado correctamente",
          };
        }
      } catch (e) {
        return {
          status: e.response.status,
          message: "Error en cargar del archivo: " + e.response.data.message,
        };
      }
    }
  }
}

function modelFile(data) {
  return {
    id: data.id,
    name: data.name,
    uploadDate: new Date(Date.parse(data.uploadDate)).toLocaleDateString(
      "en-US"
    ),
    user: {
      id: data.user.id,
      name: data.user.name,
      company: {
        id: data.user.company.id,
        name: data.user.company.name,
      },
    },
    company: {
      id: data.company.id,
      name: data.company.name,
    },
    rows: data.numberRows,
    initialBalance: data.initialBalance,
    year: data.year,
  };
}

export { FileService };
