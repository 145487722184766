export function modelCompany(company) {
    return {
      id: company.id,
      name: company.name,
      address: company.address,
      manager: company.manager,
      phone: company.phone,
      state: company.state ? company.state : "A",
      license : company.license
    };
  }