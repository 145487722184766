import { BASE_URL } from "../../constants";
import "./About.css";
import { BiPieChartAlt2, BiColumns, BiTrendingUp } from "react-icons/bi";
import YouTube from "react-youtube";

function AboutUI() {
  return (
    <div id="aboutui" className="aboutContainer">
      <div className="row opacity-100">
        <div className="col-12 text-center">
          <img
            src={BASE_URL + "/assets/img/fondo-about.png"}
            alt="logo"
            className="w-100 opacity-75"
          />
        </div>
      </div>
      <div className="row my-5 px-3 justify-content-center">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center px-5 py-3">
                <div className="col-3 text-center">
                  <BiPieChartAlt2 size="110px" />
                </div>
                <div className="col-9 text-end">
                  <h1>
                    <strong>
                      ¿Qué pretendemos hacer con el estudio que denominamos QUE
                      PASÓ CON EL DINERO?
                    </strong>
                  </h1>
                  <br />
                  <p>
                    Esto es, a través de TODOS los registros contables de la
                    empresa, para luego analizar los movimientos del efectivo
                    teniendo en cuenta las actividades operativas, de inversión
                    y financiamiento realizadas al interior de cada
                    organización.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5 px-3 justify-content-center">
        <div className="col-3 text-center">
          <img
            src={BASE_URL + "/assets/img/logoIzq.png"}
            alt="logo"
            className="w-75"
          />
        </div>
        <div className="col-8 ">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center px-5 py-3">
                <div className="col-9 text- start">
                  <h1>
                    <strong>¿Y COMO FUNCIONA?</strong>
                  </h1>
                  <br />
                  <p>
                    Y para ello logramos desarrollar el «Estado de flujo de
                    efectivo por el método directo» el cual nos mostrará con
                    todo un lujo de detalles a través de las contrapartidas
                    contables de las entradas y salidas de caja y bancos.
                  </p>
                </div>
                <div className="col-3 text-center">
                  <BiColumns size="110px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-5 px-3 justify-content-center">
        <div className="col-8">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center px-5 py-3">
                <div className="col-3 text-center">
                  <BiTrendingUp size="110px" />
                </div>
                <div className="col-9 text-end">
                  <h1>
                    <strong>¿QUE VENTAJAS OFRECE ESTA HERRAMIENTA?</strong>
                  </h1>
                  <br />
                  Por fin en toda Latinoamérica logramos hacer el ESTADO DE
                  FLUJOS DE EFECTIVO POR EL METODO DIRECTO, con toda la
                  trazabilidad de las operaciones, para llegar a encontrar
                  puntualmente que pasó con el dinero y así poder dormir
                  tranquilo....
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 text-center">
          <img
            src={BASE_URL + "/assets/img/logoDer.png"}
            alt="logo"
            className="w-75"
          />
        </div>
      </div>
      <div className="row">
        <div className="col my-5 px-3 justify-content-center">
          <div className="youtube-container">
            <YouTube className="fullwidth" videoId={"UWzpGo2dZz8"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export { AboutUI };
