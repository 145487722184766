import LoadingModal from "../utils/LoadingModal/LoadingModal";
import {
  BsTelephone,
  BsBuilding,
  BsFillPersonFill,
  BsPerson,
} from "react-icons/bs";

function UserRegisterUI(props) {
  const {
    formik,
    companiesArray,
    initialEmail,
    loading,
    mesaggeModal,
    functionButtonModal,
  } = props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              <div className="mb-3 text-center">
                <p className="fw-bold fs-3">Registrar un nuevo Usuario</p>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-5">
                    <label htmlFor="nameInput" className="form-text">
                      Nombre
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsFillPersonFill size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        value={formik.values.name}
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="lastnameInput" className="form-text">
                      Apellido
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsPerson size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="lastnameInput"
                        value={formik.values.lastname}
                        onChange={(e) =>
                          formik.setFieldValue("lastname", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-11">
                    <label htmlFor="idInput" className="form-text">
                      Correo Electrónico
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">@</span>
                      <input
                        type="text"
                        className="form-control"
                        id="idInput"
                        value={formik.values.id}
                        readOnly={initialEmail}
                        onChange={(e) =>
                          formik.setFieldValue("id", e.target.value)
                        }
                        disabled={initialEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-5">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="passwordInput" className="form-text">
                          Contraseña
                        </label>
                        <div className="input-group mb-3">
                          <span className="input-group-text">***</span>
                          <input
                            type="password"
                            className="form-control"
                            id="passwordInput"
                            value={formik.values.password}
                            onChange={(e) =>
                              formik.setFieldValue("password", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col  fw-lighter lh-sm opacity-50"
                        style={{ fontSize: 12 }}
                      >
                        <ul>
                          <li>Mínimo 8 caracteres</li>
                          <li>Incluye números y letras</li>
                          <li>
                            Debe tener al menos una letra en mayúscula y una en
                            minúscula
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <label
                      htmlFor="passwordConfirmationInput"
                      className="form-text"
                    >
                      Confirma Contraseña
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">***</span>
                      <input
                        type="password"
                        className="form-control"
                        id="passwordConfirmationInput"
                        value={formik.values.passwordConfirmation}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "passwordConfirmation",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-5">
                    <label htmlFor="phoneInput" className="form-text">
                      Teléfono
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsTelephone size="1rem" color="black" />
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        id="phoneInput"
                        value={formik.values.phone}
                        onChange={(e) =>
                          formik.setFieldValue("phone", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <label htmlFor="companySelect" className="form-text">
                      Empresa
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsBuilding size="1rem" color="black" />
                      </span>
                      <select
                        className="form-select"
                        id="companySelect"
                        value={formik.values.company}
                        onChange={(e) =>
                          formik.setFieldValue("company", e.target.value)
                        }
                      >
                        <option value="">Selección Empresa</option>
                        {companiesArray.map((company) => {
                          return (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row bg-danger text-danger bg-opacity-25">
                {Object.keys(formik.errors).map(
                  (key) =>
                    formik.errors[key] && (
                      <small key={key}>
                        <li>{formik.errors[key]}</li>
                      </small>
                    )
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#loadingModal"
                  onClick={formik.handleSubmit}
                >
                  Crear Usuario
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal
        loadingTitle={"Creando Usuario"}
        doneTitle={mesaggeModal}
        loading={loading}
        functionButton={functionButtonModal}
      />
    </div>
  );
}

export { UserRegisterUI };
