import LoadingModal from "../utils/LoadingModal/LoadingModal";
import {
  BsTelephone,
  BsBuilding,
  BsShop,
  BsPersonBadge,
  BsReceipt,
} from "react-icons/bs";

function CompanyRegisterUI(props) {
  const { formik, initialLicense, loading, mesaggeModal, functionButtonModal } =
    props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center ">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              <div className="mb-3 text-center">
                <p className="fw-bold fs-3">Registrar un nueva empresa</p>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-7">
                    <label htmlFor="nameInput" className="form-text">
                      Nombre
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsBuilding size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        value={formik.values.name}
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <label htmlFor="idInput" className="form-text">
                      NIT
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">#</span>
                      <input
                        type="number"
                        className="form-control"
                        id="idInput"
                        value={formik.values.id}
                        onChange={(e) =>
                          formik.setFieldValue("id", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

            
              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-7">
                    <label htmlFor="addressInput" className="form-text">
                      Dirección
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsShop size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="addressInput"
                        value={formik.values.address}
                        onChange={(e) =>
                          formik.setFieldValue("address", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <label htmlFor="phoneInput" className="form-text">
                      Teléfono
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsTelephone size="1rem" color="black" />
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        id="phoneInput"
                        value={formik.values.phone}
                        onChange={(e) =>
                          formik.setFieldValue("phone", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-6">
                    <label htmlFor="managerInput" className="form-text">
                      Persona Encargada
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsPersonBadge size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="managerInput"
                        value={formik.values.manager}
                        onChange={(e) =>
                          formik.setFieldValue("manager", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-5">
                    <label htmlFor="licenseInput" className="form-text">
                      Licencia
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsReceipt size="1rem" color="black" />
                      </span>

                      <input
                        type="text"
                        className="form-control"
                        id="licenseInput"
                        readOnly={initialLicense}
                        value={formik.values.license}
                        onChange={(e) =>
                          formik.setFieldValue("license", e.target.value)
                        }
                        disabled={initialLicense}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row bg-danger text-danger bg-opacity-25">
                {Object.keys(formik.errors).map(
                  (key) =>
                    formik.errors[key] && (
                      <small key={key}>
                        <li>{formik.errors[key]}</li>
                      </small>
                    )
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#loadingModal"
                  onClick={formik.handleSubmit}
                >
                  Crear Empresa
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal
        loadingTitle={"Creando Empresa"}
        doneTitle={mesaggeModal}
        loading={loading}
        functionButton={functionButtonModal}
      />
    </div>
  );
}

export { CompanyRegisterUI };
