import WithoutLicense from "../WithoutLicense/WithoutLicense";
import LoadingModal from "../utils/LoadingModal/LoadingModal";
import { IoDocumentTextOutline } from "react-icons/io5";

function UploadFileUI(props) {
  const {
    readFile,
    inputFile,
    correctData,
    errorData,
    loading,
    sendData,
    companiesArray,
    handlerCompanySelect,
    setYearFile,
    setExceptionsAccounts,
    auth,
    mesaggeModal,
    functionButtonModal,
    availableLicense,
    handleFileChange,
    actualRegister,
    lengthDataFile,
  } = props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              <>
                {auth && auth.role === "ADMIN" && (
                  <>
                    <div className="row my-3 justify-content-center">
                      <div className="col-8">
                        <label htmlFor="companySelect" className="form-text">
                          Empresa
                        </label>
                        <select
                          className="form-select"
                          id="companySelect"
                          onChange={(e) => handlerCompanySelect(e.target.value)}
                        >
                          <option value="">Selección Empresa</option>
                          {companiesArray.map((company) => {
                            return (
                              <option key={company.id} value={company.id}>
                                {company.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </>
                )}

                {availableLicense ? (
                  <>
                    <div className="row my-3 justify-content-center">
                      <div className="col-8">
                        <div className="row my-4 align-items-end">
                          <div className="col-6">
                            <label htmlFor="yearInput" className="form-text">
                              Año
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="yearInput"
                              onChange={(e) => setYearFile(e.target.value)}
                            />
                            <div className="form-text">
                              El año ingresado se considerará de enero a
                              diciembre
                            </div>
                          </div>
                          <div className="col-6">
                            <label
                              htmlFor="exceptionsAccountsInput"
                              className="form-text"
                            >
                              Excepciones de cuentas diferentes a la cuenta 11
                              que integra el "Efectivo y equivalentes al
                              efectivo"
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="exceptionsAccountsInput"
                              onChange={(e) =>
                                setExceptionsAccounts(e.target.value)
                              }
                            />
                            <div className="form-text">
                              Ingresar las cuentas separadas por comas, ejemplo:
                              12250502, 12450501, 12959504
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-end justify-content-center">
                      <div className="col-6">
                        <label htmlFor="idFile" className="form-text">
                          Carga tu archivo
                        </label>
                        <input
                          className="form-control"
                          id="idFile"
                          type="file"
                          ref={inputFile}
                          accept=".xlsx, .xls, .csv"
                          onChange={handleFileChange}
                        />
                      </div>
                      <div className="col-2 ">
                        <button className="btn btn-primary" onClick={readFile}>
                          Validar Archivo
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row my-3 justify-content-center">
                    <div className="col-8">
                      <WithoutLicense />
                    </div>
                  </div>
                )}
              </>

              <hr />
              <div className="row text-center">
                {errorData.length > 0 && (
                  <div>
                    <p className=" bg-danger text-danger bg-opacity-25 mb-0">
                      Se tienen {errorData.length} errores en el archivo
                    </p>
                    <p className=" bg-danger text-danger bg-opacity-25 ">
                      Corregir directamente en el archivo y volver a cargar
                    </p>
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Error</th>
                          <th scope="col">Fila</th>
                        </tr>
                      </thead>
                      <tbody>
                        {errorData.map((error, index) => {
                          return (
                            <tr key={index}>
                              <td>{error.error}</td>
                              <td>{error.row}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#loadingModal"
                      onClick={sendData}
                    >
                      Omitir errores y cargar archivo
                    </button>
                  </div>
                )}
                {errorData.length === 0 && correctData.length > 0 && (
                  <div>
                    <p>Su archivo no contine errores</p>
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#loadingModal"
                      onClick={sendData}
                    >
                      Enviar Archivo
                    </button>
                  </div>
                )}
                {!loading &&
                  errorData.length === 0 &&
                  correctData.length === 0 && (
                    <div className="my-5 py-3">
                      <IoDocumentTextOutline size="5rem" color="gray" />
                      <p>Carga un documento</p>
                    </div>
                  )}

                {loading && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <p>{actualRegister}/{lengthDataFile}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <LoadingModal
            loadingTitle={"Cargando archivo"}
            doneTitle={mesaggeModal}
            loading={loading}
            functionButton={functionButtonModal}
          />
        </div>
      </div>
    </div>
  );
}

export { UploadFileUI };
