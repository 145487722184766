import React, { useRef, useState, useEffect } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import useAuth from "../../hooks/useAuth";

import { FileModel } from "../../models/FileModel";
import { DataService } from "../../services/DataService";

import { UploadFileUI } from "./UploadFileUI";
import { useNavigate } from "react-router-dom";
import { URL_ROUTE } from "../../constants";
import useAxios from "../../hooks/useAxios";

function UploadFile() {
  const { generalRequest } = useAxios();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const [correctData, setCorrectData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [dataFile, setDataFile] = useState([]);

  const [loading, setLoading] = useState(false);
  const [mesaggeModal, setMessageModal] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState();
  const [yearFile, setYearFile] = useState();
  const [exceptionsAccounts, setExceptionsAccounts] = useState();
  const [availableLicense, setAvailableLicense] = useState(true);
  const [actualRegister, setActualRegister] = useState(0);
  const [lengthDataFile, setLengthDataFile] = useState(0);

  const inputFile = useRef();
  const fileModel = new FileModel();

  useEffect(() => {
    auth.role === "ADMIN" && loadCompanies();
    auth.role !== "ADMIN" && checkLicenseByCompany(auth.company.id);
  }, []);

  useEffect(() => {
    if (dataFile) {
      if (dataFile.length > 0) {
        setLengthDataFile(dataFile.length);
        processData();
      }
    }
  }, [dataFile]);

  useEffect(() => {
    correctData.length > 0 && setLoading(false);
  }, [correctData]);

  async function loadCompanies() {
    await generalRequest("/company", "GET", false).then((response) => {
      setCompanies(response.data);
    });
  }

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  //Lee archivo Excel
  async function readFile() {
    console.log("Inicia lectura");
    if (yearFile) {
      setCorrectData([]);
      setErrorData([]);
      setLoading(true);
      if (file) {
        const fileExtension = file.name.split(".").pop();

        if (fileExtension === "xlsx" || fileExtension === "xls") {
          console.log("EXCEL");
          await parseExcel();
        } else if (fileExtension === "csv") {
          console.log("CSV");
          const results = await parseCSV(file);
          setDataFile(results.data);
        } else {
          alert("Formato no válido");
        }
      }
    } else {
      const messageAlert = !yearFile ? "(Año) " : "";
      alert("Por favor ingresar: " + messageAlert);
    }

    // console.log("Inicia lectura")
    // if (yearFile) {
    //   setCorrectData([]);
    //   setErrorData([]);
    //   //Lectura de Excel
    //   setLoading(true);
    //   let reader = new FileReader();
    //   reader.readAsArrayBuffer(inputFile.current.files[0]);
    //   reader.onloadend = async (el) => {
    //     var data = new Uint8Array(el.target.result);
    //     var workbook = XLSX.read(data, { type: "array" });
    //     var data = XLSX.utils.sheet_to_json(workbook.Sheets["Hoja1"]);
    //     setDataFile(data);
    //   };
    // } else {
    //   const messageAlert =  (!yearFile ? "(Año) " : "")
    //   alert("Por favor ingresar: " + messageAlert)
    // }
  }

  const parseExcel = async () => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(inputFile.current.files[0]);
    reader.onloadend = async (el) => {
      var data = new Uint8Array(el.target.result);
      var workbook = XLSX.read(data, { type: "array" });
      var data = XLSX.utils.sheet_to_json(workbook.Sheets["Hoja1"]);
      setDataFile(data);
    };
  };

  const parseCSV = (csvFile) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          resolve(results);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  };

  //Modela archivo
  async function processData() {
    console.log("Modela archivo");
    console.log(dataFile);
    const fileExtension = file.name.split(".").pop();

    if (fileExtension === "xlsx" || fileExtension === "xls") {
      await fileModel.fileArrayToModelExcel(dataFile).then(async (dataModeled) => {
        validateData(dataModeled);
      });
    } else if (fileExtension === "csv") {
      await fileModel.fileArrayToModelCSV(dataFile).then(async (dataModeled) => {
        validateData(dataModeled);
      });
    }
    
  }

  //Valida la información
  function validateData(data) {
    console.log("Validando info");
    const correctArray = [];
    const errorArray = [];
    data.forEach((element, key) => {
      try {
        //if (element.documentType === "RC" && element.document === 50) {
        //}
        if (key % 1000 === 0 || key === lengthDataFile) {
          setActualRegister(key);
          console.log(key);
        }

        fileModel.validateElement(element, yearFile) &&
          correctArray.push(element);
      } catch (error) {
        errorArray.push({ ...element, error: error });
      }
    });
    setCorrectData(correctArray);
    setErrorData(errorArray);
  }

  //Envía información a la API
  async function sendData() {
    try {
      const user = auth;
      if (auth && auth.role === "ADMIN") {
        if (companySelected) {
          user.company.id = companySelected;
        } else {
          setMessageModal("Selecciona Empresa...");
          return false;
        }
      }
      setErrorData([]);
      setLoading(true);
      console.log(correctData);
      const dataService = new DataService();
      await dataService
        .sendData(
          inputFile.current.files[0].name,
          user,
          correctData,
          yearFile,
          exceptionsAccounts
        )
        .then((response) => {
          response.status === 200 && setSuccessModal(true);
          setMessageModal(response.message);
          setLoading(false);
        });
    } catch (error) {}
  }

  function functionButtonModal() {
    successModal && navigate(URL_ROUTE + "/historyFile");
  }

  async function handlerCompanySelect(companyId) {
    setCompanySelected(companyId);
    checkLicenseByCompany(companyId);
    //setCompanySelected(companyId);
  }

  async function checkLicenseByCompany(companyId) {
    await generalRequest("/file/available/" + companyId, "GET", false).then(
      (response) => {
        response.data[0] <= 0
          ? setAvailableLicense(false)
          : setAvailableLicense(true);
      }
    );
  }

  return (
    <UploadFileUI
      readFile={readFile}
      inputFile={inputFile}
      correctData={correctData}
      errorData={errorData}
      loading={loading}
      sendData={sendData}
      companiesArray={companies}
      handlerCompanySelect={handlerCompanySelect}
      setYearFile={setYearFile}
      auth={auth}
      mesaggeModal={mesaggeModal}
      functionButtonModal={functionButtonModal}
      setExceptionsAccounts={setExceptionsAccounts}
      availableLicense={availableLicense}
      handleFileChange={handleFileChange}
      actualRegister={actualRegister}
      lengthDataFile={lengthDataFile}
    />
  );
}

export default UploadFile;
