import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryLabel,
  VictoryAxis,
} from "victory";
import { MONTHS } from "../../constants";

function ChartCashBalanceUI(props) {
  const { initialBalance, getTotalFlowCash, year } = props;

  const dataChart = [
    {
      x: "Inicial",
      y: initialBalance,
    },
  ];

  [...Array(12).keys()].map((i) =>
    dataChart.push({
      x: MONTHS[i].substring(0, 3),
      y: getTotalFlowCash(i, false),
    })
  );

  return (
    <div className="row justify-content-center">
      <div className="col-10">
        <VictoryChart
          domainPadding={20}
           style={{background: { fill: "#d0d0d0" }
          }}
        >
           <VictoryLabel
            text={`SALDOS DE EFECTIVO - ${year}`}
            style={[
              {  fontSize: 12 },
            ]}
            x={225}
            y={10}
            textAnchor="middle"
          />
          <VictoryAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />
          <VictoryLine
            labels={({ datum }) =>
              (new Intl.NumberFormat("es-CO").format((datum.y/1000000).toFixed(2))).toString() + ' M'
            }
            labelComponent={
              <VictoryLabel
                angle={-90}
                backgroundStyle={{ fill: "white" }}
                backgroundPadding={1}
                style={[{ fontSize: 6 }]}
                dx={22}
                dy={0}
              />
            }
            interpolation="natural"
            data={dataChart}
          />
          <VictoryScatter
            style={{ data: { fill: "#c43a31" } }}
            size={2}
            data={dataChart}
          />
        </VictoryChart>
      </div>
    </div>
  );
}

export { ChartCashBalanceUI };
