import React, { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useLocation, useNavigate } from "react-router-dom";
import { FlowCashDetailUI } from "./FlowCashDetailUI";
import { FileModel } from "../../models/FileModel";
import useFlowCash from "../../hooks/useFlowCash";
import { URL_ROUTE } from "../../constants";

function FlowCashDetail() {
  const location = useLocation();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Flujo-efectivo",
    sheet: "FlujoEfectivo",
  });
  const {
    data,
    filterDataByDocumentTypeAndDocumentId
  } = useFlowCash();

  function currentFormat(value) {
    return new Intl.NumberFormat("es-CO").format(value);
  }

  function getTotalValue(data, column) {
    const columnMap = {
      Valor_Flujo: 'Valor_Flujo',
      Debito: 'Debito',
      Credito: 'Credito'
    };
  
    let total = 0;
    
    data.forEach((item) => {
      if (columnMap[column] && item[columnMap[column]]) {
        total += parseFloat(item[columnMap[column]].replace(',','.'));
      }
    });

    return total;
  }

  function goToFlowCash(){
    navigate(-1);
  }

  async function exportCellInfo(documentType, documentId) {
    if (data.length !== 0) {
      const fileModel = new FileModel();
      const dataFiltered = filterDataByDocumentTypeAndDocumentId(documentType, documentId);
      console.log(dataFiltered);
     if (dataFiltered.length !== 0) {
        await fileModel.fileExportModel(dataFiltered).then((response) => {
          console.log(response);
          navigate(URL_ROUTE + "/flowCashDetail/document", { state: response });
        });
      }
    }
  }

  return (
    <FlowCashDetailUI
      data={location.state}
      currentFormat={currentFormat}
      tableRef={tableRef}
      onDownload={onDownload}
      getTotalValue={getTotalValue}
      goToFlowCash={goToFlowCash}
      exportCellInfo={exportCellInfo}
    />
  );
}

export default FlowCashDetail;
