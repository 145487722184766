import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryLabel,
  VictoryAxis,
  VictoryBar,
  VictoryGroup,
  VictoryLegend,
} from "victory";
import { MONTHS } from "../../constants";

function ChartIOUI(props) {
  const {
    initialBalance,
    getTotalFlowCash,
    getTotalMonthValueByMovementType,
  } = props;

  const dataChart = [
    {
      x: "Inicial",
      y: initialBalance,
    },
  ];

  const dataChartFlow = [...dataChart];

  [...Array(12).keys()].map((i) =>
    dataChartFlow.push({
      x: MONTHS[i].substring(0, 3),
      y: getTotalFlowCash(i, false),
    })
  );

  const dataChartIn = [
    {
      x: "Inicial",
      y: 0,
    },
  ];

  [...Array(12).keys()].map((i) =>
    dataChartIn.push({
      x: MONTHS[i].substring(0, 3),
      y: getTotalMonthValueByMovementType(i, "E", false),
    })
  );

  const dataChartOut = [
    {
      x: "Inicial",
      y: 0,
    },
  ];

  [...Array(12).keys()].map((i) =>
    dataChartOut.push({
      x: MONTHS[i].substring(0, 3),
      y: -1 * getTotalMonthValueByMovementType(i, "S", false),
    })
  );

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <VictoryChart
          domainPadding={20}
          style={{ background: { fill: "#d0d0d0" } }}
        >
          <VictoryLabel
            style={[{ fontSize: 12 }]}
            text="ENTRADAS Y SALIDAS DE EFECTIVO"
            x={225}
            y={10}
            textAnchor="middle"
          />

          <VictoryLegend
            x={50}
            y={50}
            centerTitle
            orientation="horizontal"
            gutter={10}
            style={{
              border: { stroke: "black" },
              labels: { fontSize: 6 },
            }}
            data={[
              { name: "Entrada", symbol: { fill: "#5a81c7" } },
              { name: "Salida", symbol: { fill: "#eb8b4b" } },
            ]}
          />

          <VictoryAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />

          <VictoryGroup offset={11} colorScale={"qualitative"}>
            <VictoryBar
              data={dataChartIn}
              style={{ data: { fill: "#5a81c7" } }}
              barWidth={11}
              labels={({ datum }) =>
                new Intl.NumberFormat("es-CO")
                  .format((datum.y / 1000000).toFixed(2))
                  .toString() + " M"
              }
              labelComponent={
                <VictoryLabel
                  angle={-90}
                  backgroundStyle={{ fill: "#a2b2dd" }}
                  backgroundPadding={1}
                  style={[{ fontSize: 5, fill: "black" }]}
                  dx={17}
                  dy={2}
                />
              }
            />
            <VictoryBar
              data={dataChartOut}
              style={{ data: { fill: "#eb8b4b" } }}
              barWidth={11}
              labels={({ datum }) =>
                new Intl.NumberFormat("es-CO")
                  .format((datum.y / 1000000).toFixed(2))
                  .toString() + " M"
              }
              labelComponent={
                <VictoryLabel
                  angle={-90}
                  backgroundStyle={{ fill: "#ffda96" }}
                  backgroundPadding={1}
                  style={[{ fontSize: 5, fill: "black" }]}
                  dx={-16}
                  dy={0}
                />
              }
            />
          </VictoryGroup>

          <VictoryLine
            labels={({ datum }) =>
              new Intl.NumberFormat("es-CO")
                .format((datum.y / 1000000).toFixed(2))
                .toString() + " M"
            }
            labelComponent={
              <VictoryLabel
                backgroundStyle={{ fill: "white" }}
                backgroundPadding={1}
                style={[{ fontSize: 4, fill: "#c43a31" }]}
                dy={0}
                dx={12}
              />
            }
            interpolation="natural"
            data={dataChartFlow}
          />

          <VictoryScatter
            style={{ data: { fill: "#c43a31" } }}
            size={2}
            data={dataChartFlow}
          />
        </VictoryChart>
      </div>
    </div>
  );
}

export { ChartIOUI };
