
function WithoutLicenseUI(props) {

    const {company} = props;

  return (
    <div>
        <p>La empresa {company} ha superado el límite de archivos por licencia, por favor contacte al administrador</p>
    </div>
  )
}

export {WithoutLicenseUI}