import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataService } from "../../services/DataService";
import { NiifService } from "../../services/NiifService";
import { FlowCashUI } from "./FlowCashUI";
import useAuth from "../../hooks/useAuth";
import useFlowCash from "../../hooks/useFlowCash";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


import { useDownloadExcel } from "react-export-table-to-excel";
import { FileModel } from "../../models/FileModel";
import { URL_ROUTE } from "../../constants";

function FlowCash() {
  const [searchParams] = useSearchParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState(8)

  const {
    data,
    setData,
    fileId,
    setFileId,
    classifyNiif,
    getTotalMonthValueByNiif,
    getValueByMonthByNiifId,
    filterDataByMonthAndNiifId,
    getSubstractCreditsDebitsByMonth,
    formatMillionFormat,
  } = useFlowCash();

  //Hook inicilization for Export Excel File
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      "Flujo-efectivo-" + searchParams.get("id") + "-" + auth.company.name,
    sheet: "FlujoEfectivo",
  });

  const [operationActivitiesCharge, setOperationActivitiesCharge] = useState(
    []
  );
  const [operationActivitiesPayment, setOperationActivitiesPayment] = useState(
    []
  );
  const [operationActivities, setOperationActivities] = useState([]);
  const [investmentActivities, setInvestmentActivities] = useState([]);
  const [financingActivities, setFinancingActivities] = useState([]);

  //Function to get the data from API
  async function loadData(id) {
      
      const dataService = new DataService();

      await dataService.getData(id).then(async (response) => {
        console.log(response.data);
        if (response.status === 200) {
          setLoading(false);
          setData(response.data);
        }
      });
  
  }

  //Function to get NiiF from API and Clasify the niif by activity
  async function loadNiif() {
    const niifService = new NiifService();

    await niifService.getNiifs().then(async (response) => {
      const niifsArray = response;

      //Classify the niif by activity
      setOperationActivities(classifyNiif(niifsArray, 1));
      setOperationActivitiesCharge(classifyNiif(niifsArray, 1, "C"));
      setOperationActivitiesPayment(classifyNiif(niifsArray, 1, "P"));
      setInvestmentActivities(classifyNiif(niifsArray, 2));
      setFinancingActivities(classifyNiif(niifsArray, 3));
    });
  }

  async function exportTotalInfo(month, dataArray) {
    const niifArray = [];
    dataArray.forEach((data) => niifArray.push(data.id));
    return exportCellInfo(month, niifArray.flat());
  }

  async function exportCellInfo(month, niifId) {
    if (niifId.length === 0) {
      return 0;
    }

    if (data.length !== 0) {
      const fileModel = new FileModel();
      const dataFiltered = filterDataByMonthAndNiifId(month, niifId);
      console.log(dataFiltered);
      if (dataFiltered.length !== 0) {
        await fileModel.fileExportModel(dataFiltered).then((response) => {
          console.log(response);
          navigate(URL_ROUTE + "/flowCashDetail", { state: response });
          // exportFromJSON({
          //   data: response,
          //   fileName: "Flujo-efectivo-Detalle-" + searchParams.get("id") + "-" + auth.company.name + "-" + niifId,
          //   exportType: "xls",
          // });
        });
      }
    }
  }

  function calculateMonthValue(month) {
    return getTotalMonthValueByNiif(
      month,
      [
        ...operationActivitiesCharge,
        ...operationActivitiesPayment,
        ...operationActivities,
        ...investmentActivities,
        ...financingActivities,
      ],
      false
    );
  }

  function getInitialBalance(month, formatted) {
    const initialBalance = Number(searchParams.get("initialBalance"));

    // if (month === "T") {
    //   if (formatted) {
    //     return new Intl.NumberFormat("es-CO").format(initialBalance);
    //   } else {
    //     return initialBalance;
    //   }
    // }

    let value = initialBalance;

    for (let i = 0; i < (month !== "T" ? month : 12); i++) {
      value += calculateMonthValue(i);
    }

    if (formatted) {
      return formatMillionFormat(value);
    } else {
      return value;
    }
  }

  function getTotalFlowCash(month, formatted) {
   
      
    const monthInitialBalance = getInitialBalance(month, false);

    const monthFlowCash = getTotalMonthValueByNiif(
      month,
      [
        ...operationActivitiesCharge,
        ...operationActivitiesPayment,
        ...operationActivities,
        ...investmentActivities,
        ...financingActivities,
      ],
      false
    );

    const value = monthInitialBalance + monthFlowCash;

    if (formatted) {
      return formatMillionFormat(value);
    } else {
      return value;
    }
  }

  //Function to get comprobation total
  const getTotalComprobation = (month, formatted) => {
    const value =
      getInitialBalance(month, false) +
      getSubstractCreditsDebitsByMonth(month, false);

    if (formatted) {
      return formatMillionFormat(value);
    } else {
      return value;
    }
  };

  //Function to get comprobation
  const getComprobation = (month, formatted) => {
    const value = Math.abs(
      getTotalFlowCash(month, false) - getTotalComprobation(month, false)
    );

    if (formatted) {
      return formatMillionFormat(value);
    } else {
      return value;
    }
  };

  useEffect(() => {
    const id = searchParams.get("id");

    if(id !== fileId){
      console.log("Cargando datos");
      setFileId(id);
      setLoading(true);
      loadData(id);
    }
    loadNiif();
  }, []);

  function currentFormat(value) {
    return formatMillionFormat(value);
  }

  function goToCharts(){
    const id = searchParams.get("id");
    const initialBalance = searchParams.get("initialBalance");
    navigate(`${URL_ROUTE}/charts?id=${id}&initialBalance=${initialBalance}`);
  }

  //PDF DOWNLOAD
  function onDownloadPDF(){
    
      const input = document.getElementById('flowCashTable'); // ID de la tabla que deseas exportar
    
      const pdfWidth = 594; // Ancho personalizado en mm
      const pdfHeight = 910; // Altura personalizada en mm

  html2canvas(input)
    .then((canvas) => {
      const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);
      const imgData = canvas.toDataURL('image/png');

      // Ajusta el tamaño de la imagen al tamaño de la página PDF
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save('tabla.pdf');
    });
    
  }

  //Functions to increase fontSize

  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 2);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => prevSize - 2);
  };

  return (
    <FlowCashUI
      operationActivitiesCharge={operationActivitiesCharge}
      operationActivitiesPayment={operationActivitiesPayment}
      operationActivities={operationActivities}
      investmentActivities={investmentActivities}
      financingActivities={financingActivities}

      getValueByMonthByNiifId={getValueByMonthByNiifId}
      getTotalMonthValueByNiif={getTotalMonthValueByNiif}
      tableRef={tableRef}
      onDownload={onDownload}
      onDownloadPDF={onDownloadPDF}

      exportCellInfo={exportCellInfo}
      exportTotalInfo={exportTotalInfo}
      getInitialBalance={getInitialBalance}
      getTotalFlowCash={getTotalFlowCash}
      getTotalComprobation={getTotalComprobation}
      getComprobation={getComprobation}
      currentFormat={currentFormat}
      loading={loading}
      goToCharts={goToCharts}

      fontSize={fontSize}
      increaseFontSize={increaseFontSize}
      decreaseFontSize={decreaseFontSize}

    />
  );
}

export default FlowCash;
