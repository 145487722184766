function ErrorsUI(props) {
  const { textError, buttonLabel, buttonFunction } = props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              <div className="mb-3 text-center">
                <p className="fw-bold fs-3">Se ha encontrado un error,</p>
              </div>
              <div className="text-center">
                <p className=" bg-danger text-danger bg-opacity-25">
                  {textError}
                </p>
                <button className="btn btn-primary" onClick={buttonFunction}>
                  {buttonLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ErrorsUI };
