import "./Login.css";
import { BiUser } from "react-icons/bi";
import { MdPassword } from "react-icons/md";
import { BASE_URL } from "../../constants";

function LoginUI(props) {
  const { formik } = props;

  return (
    <div>
      <div className="row justify-content-center align-items-center text-center loginContainer w-100 h-100">
       <div className="col-2">
        <img src={BASE_URL + "/assets/img/logoIzq.png"} className="w-100" />
      </div>
        <div className="col-8">
          <div className="row">
            <div className="col-5 p-0">
              <img
                src= {BASE_URL + "/assets/img/img-login.jpg"}
                className="w-100"
              />
            </div>
            <div className="col-7 ">
              <div className="row h-100">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="mt-5 pt-4">
                      <p className="fw-semibold text-primary fs-2">
                        ¿Qué pasó con el dinero?
                      </p>
                      <p>Bienvenido,</p>
                      <div className=" text-center pt-2"></div>
                      <div className="row mb-3 px-5">
                        <div className="col-2">
                          <BiUser size="2rem" color="gray" />
                        </div>
                        <div className="col-10">
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Correo electrónico"
                            value={formik.values.username}
                            onChange={(e) =>
                              formik.setFieldValue("username", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row mb-3 px-5">
                        <div className="col-2">
                          <MdPassword size="2rem" color="gray" />
                        </div>
                        <div className="col-10">
                        <input
                          type="password"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Contraseña"
                          value={formik.values.password}
                          onChange={(e) =>
                            formik.setFieldValue("password", e.target.value)
                          }
                        />
                      </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={formik.handleSubmit}
                        >
                          Iniciar Sesión
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <img src={BASE_URL + "/assets/img/logoDer.png"} className="w-100" />
        </div>
      </div>
    </div>
  );
}

export { LoginUI };
