import axios from "axios";

import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

import { TokenService } from "./ApiServices/TokenService";

class DataService {
  async sendData(filename, user, data, year, exceptionAccounts) {
    const request = {
      filename,
      user,
      year,
      data,
      exceptionsAccounts : exceptionAccounts ? exceptionAccounts.split(",").map((account)=> account.trim()) : []
    };

    console.log(request);

    const tokenService = new TokenService();
    const url = API_BASE_URL + "/private/file";
    if (data && tokenService.verifyToken()) {
      try {
        //Sentencia HTTP
        const response = await axios({
          url,
          method: "POST",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem(ACCESS_TOKEN)
            )}`,
          },
          data: request,
        });

        if (response.status === 200) {
          return {
            status: response.status,
            message: "Archivo cargado correctamente",
          };
        }
      } catch (e) {
        return {
          status: e.response.status,
          message: "Error en cargar del archivo: " + e.response.data.message,
        };
      }
    }
  }

  async getData(fileId) {
    const tokenService = new TokenService();
    const url = API_BASE_URL + "/private/fileData/data/" + fileId;
    if (tokenService.verifyToken()) {
      try {
        //Sentencia HTTP
        const response = await axios({
          url,
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem(ACCESS_TOKEN)
            )}`,
            'Accept-Encoding': 'gzip, deflate',
          },
        });

    

        if (response.status === 200) {
          return {
            status: response.status,
            message: "Data cargada correctamente",
            data: response.data.map((item) => modelData(item))
          };
        }
      } catch (e) {
        return {
          status: e.response.status,
          message: "Error en cargar del archivo: " + e.response.data.message,
        };
      }
    }
  }

  async getDataOthers(fileId) {
    const tokenService = new TokenService();
    const url = API_BASE_URL + "/private/fileData/others/" + fileId;
    if (tokenService.verifyToken()) {
      try {
        //Sentencia HTTP
        const response = await axios({
          url,
          method: "GET",
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem(ACCESS_TOKEN)
            )}`,
            'Accept-Encoding': 'gzip, deflate',
          },
        });

    

        if (response.status === 200) {
          return {
            status: response.status,
            message: "Data cargada correctamente",
            data: response.data.map((item) => modelData(item))
          };
        }
      } catch (e) {
        return {
          status: e.response.status,
          message: "Error en cargar del archivo: " + e.response.data.message,
        };
      }
    }
  }


}

function modelData(data) {
  return {
    account: data.account,
    accountName: data.accountName,
    thirdParty: data.thirdParty,
    thirdPartyName: data.thirdPartyName,
    date: new Date(Date.parse(data.date)),
    documentType: data.documentType,
    document: data.document,
    details: data.details,
    initialBalance: data.initialBalance,
    debit: data.debit,
    credit: data.credit,
    row: data.row,
    record: data.record,
    netValue: data.netValue,
    flowValue: data.flowValue,
    cashDocument: data.cashDocument,
    movementType: data.movementType,
    niif: data.niif ? data.niif : ""
  };

}

export { DataService };
