function LoadingModalUI(props) {
  const { loadingTitle, doneTitle, loading, functionButton } = props;
  return (
    <div
      className="modal fade"
      id="loadingModal"
      tabIndex="-1"
      aria-labelledby="loadingModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="loadingModalLabel">
              {loadingTitle}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={functionButton}
            ></button>
          </div>
          <div className="modal-body">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Cargando...</span>
                </div>
              </div>
            ) : (
              <p>{doneTitle}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { LoadingModalUI };
