import { useState } from "react";
import { createContext } from "react";
import { TokenService } from "../services/ApiServices/TokenService";
import { ACCESS_TOKEN, API_BASE_URL } from "../constants";
import axios from "axios";


export const DataContext = createContext({
    data: [],
    setData: () => {},
    sendData: () => {},
    getData: () => {},
    modelData: () => {},
    });

export function DataProvider(props) {
    const { children } = props;

    const [data, setData] = useState(undefined);

    
    const sendData = async (filename, user, data, year, exceptionAccounts) => {
        const request = {
            filename,
            user,
            year,
            data,
            exceptionsAccounts : exceptionAccounts ? exceptionAccounts.split(",").map((account)=> account.trim()) : []
          };
      
          console.log(request);
      
          const tokenService = new TokenService();
          const url = API_BASE_URL + "/private/file";
          if (data && tokenService.verifyToken()) {
            try {
              //Sentencia HTTP
              const response = await axios({
                url,
                method: "POST",
                headers: {
                  Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN)
                  )}`,
                },
                data: request,
              });
      
              if (response.status === 200) {
                return {
                  status: response.status,
                  message: "Archivo cargado correctamente",
                };
              }
            } catch (e) {
              return {
                status: e.response.status,
                message: "Error en cargar del archivo: " + e.response.data.message,
              };
            }
          }
    };
    
    const getData = async (fileId) => {
        const tokenService = new TokenService();
        const url = API_BASE_URL + "/private/fileData/" + fileId;
        if (tokenService.verifyToken()) {
          try {
            //Sentencia HTTP
            const response = await axios({
              url,
              method: "GET",
              headers: {
                Authorization: `Bearer ${JSON.parse(
                  localStorage.getItem(ACCESS_TOKEN)
                )}`,
                'Accept-Encoding': 'gzip, deflate',
              },
            });
    
        
    
            if (response.status === 200) {
              return {
                status: response.status,
                message: "Data cargada correctamente",
                data: response.data.map((item) => modelData(item))
              };
            }
          } catch (e) {
            return {
              status: e.response.status,
              message: "Error en cargar del archivo: " + e.response.data.message,
            };
          }
        }
    };
    
    const modelData = (data) => {
        return {
            account: data.account,
            accountName: data.accountName,
            thirdParty: data.thirdParty,
            thirdPartyName: data.thirdPartyName,
            date: new Date(Date.parse(data.date)),
            documentType: data.documentType,
            document: data.document,
            details: data.details,
            initialBalance: data.initialBalance,
            debit: data.debit,
            credit: data.credit,
            row: data.row,
            record: data.record,
            netValue: data.netValue,
            flowValue: data.flowValue,
            cashDocument: data.cashDocument,
            movementType: data.movementType,
            niif: data.niif ? data.niif : ""
          };
    };

    const valueContext = {
        data,
        setData,
        sendData,
        getData,
        modelData,
    };

    return (
        <DataContext.Provider value={valueContext}>
            {children}
        </DataContext.Provider>
    );
}