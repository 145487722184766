import { useState, createContext } from "react";

export const FlowCashContext = createContext({
  data: [],
  setData: () => {},
  fileId: null,
  setFileId: () => {},
  classifyNiif: () => {},
  getTotalMonthValueByNiif: () => {},
  getValueByMonthByNiifId: () => {},
  getTotalMonthValueByMovementType: () => {},
  filterDataByMonthAndNiifId: () => {},
  filterDataByDocumentTypeAndDocumentId: () => {},
  filterDataByMonthAndMovementType: () => {},
  filterDataByMonth: () => {},
  getTotalMonthValueByAccountingMovement: () => {},
  getSubstractCreditsDebitsByMonth: () => {},
  formatMillionFormat: () => {},
});

export function FlowCashProvider(props) {
  const { children } = props;

  const [data, setData] = useState([]);

  const [fileId, setFileId] = useState(null);

  const classifyNiif = (niifsArray, activity, classification = null) => {
    if (classification === null) {
      return niifsArray.filter(
        (niif) => niif.activity.id === activity && niif.classification === null
      );
    } else {
      return niifsArray.filter(
        (niif) =>
          niif.activity.id === activity &&
          niif.classification === classification
      );
    }
  };

  //Function to get the total month value by movement type
  const getTotalMonthValueByMovementType = (month, movementType, formatted) => {
    if (data.length > 0) {
      let cellValue = 0;

      filterDataByMonthAndMovementType(month, movementType).forEach((item) => {
        cellValue += item.flowValue;
      });

      //Exception with SE movement type
      if (movementType === "E") {
        filterDataByMonthAndMovementType(month, "SE").forEach((item) => {
          cellValue += item.flowValue;
        });
      }

      if (formatted) {
        return new Intl.NumberFormat("es-CO").format(cellValue);
      } else {
        return cellValue;
      }
    }
  };

  //Function to get the total month value by accounting movement
  const getTotalMonthValueByAccountingMovement = (
    month,
    accountingMovement,
    formatted
  ) => {
    if (data.length > 0) {
      let cellValue = 0;
      filterDataByMonth(month).forEach((item) => {
        accountingMovement === "D"
          ? (cellValue += item.debit)
          : (cellValue += item.credit);
      });

      if (formatted) {
        return new Intl.NumberFormat("es-CO").format(cellValue);
      } else {
        return cellValue;
      }
    }
  };

  //Function to get the total month value  of the niif
  const getTotalMonthValueByNiif = (month, dataNiifArray, formatted) => {
    const niifArray = [];
    dataNiifArray.forEach((data) => niifArray.push(data.id));
    return getValueByMonthByNiifId(month, niifArray.flat(), formatted);
  };

  //Function get the value by month and niif especific niif
  const getValueByMonthByNiifId = (month, niifId, formatted) => {
    if (niifId.length === 0) {
      return 0;
    }

    if (data.length > 0) {
      //let cellValue = 0;

      const dataFiltered = filterDataByMonthAndNiifId(month, niifId);

      // dataFiltered.forEach((item) => {
      //   cellValue += item.flowValue;
      // });

      const cellValue = dataFiltered.reduce(
        (acc, item) => acc + item.flowValue,
        0
      );

      if (formatted) {
        return new Intl.NumberFormat("es-CO").format(cellValue);
      } else {
        return cellValue;
      }
    }
  };

  //Funtion to substract Credits - Debits by month
  const getSubstractCreditsDebitsByMonth = (month, formatted) => {
    if (data.length > 0) {
      const cellValue =
        getTotalMonthValueByAccountingMovement(month, "C", false) -
        getTotalMonthValueByAccountingMovement(month, "D", false);

      if (formatted) {
        return new Intl.NumberFormat("es-CO").format(cellValue);
      } else {
        return cellValue;
      }
    }
  };

  //Function filter the data by month and niif array
  const filterDataByMonthAndNiifId = (month, niifId) => {
    const dataFiltered = data.filter((item) => {
      if (month === "T") {
        return item.niif ? niifId.includes(item.niif.id) : false;
      } else {
        return item.niif
          ? niifId.includes(item.niif.id) && item.date.getMonth() === month
          : false;
      }
    });

    return dataFiltered;
  };
  //Function filter the data by documentType and documentId
  const filterDataByDocumentTypeAndDocumentId = (documentType, documentId) => {
    const dataFiltered = data.filter((item) => {
      return item.documentType === documentType && item.document === documentId;
    });

    return dataFiltered;
  };

  //Function filter the data by month and movement type
  const filterDataByMonthAndMovementType = (month, movementType) => {
    const dataFiltered = data.filter((item) => {
      if (item.niif === "") {
        return false;
      }

      if (month === "T") {
        return item.movementType === movementType;
      } else {
        return (
          item.date.getMonth() === month && item.movementType === movementType
        );
      }
    });

    return dataFiltered;
  };
  //Function filter the data by month
  const filterDataByMonth = (month) => {
    const dataFiltered = data.filter((item) => {
      if (item.niif === "") {
        return false;
      }
      if (month === "T") {
        return true;
      } else {
        return item.date.getMonth() === month;
      }
    });

    return dataFiltered;
  };

  //Funtion to get Values in $xxx,xxM
  const formatMillionFormat = (value) => {
    const aux = (value / 1000000).toFixed(2);
    return new Intl.NumberFormat("es-CO").format(aux) + "M";
  };

  return (
    <FlowCashContext.Provider
      value={{
        data,
        setData,
        fileId,
        setFileId,
        classifyNiif,
        getTotalMonthValueByNiif,
        getValueByMonthByNiifId,
        getTotalMonthValueByMovementType,
        filterDataByMonthAndNiifId,
        filterDataByDocumentTypeAndDocumentId,
        filterDataByMonthAndMovementType,
        filterDataByMonth,
        getTotalMonthValueByAccountingMovement,
        getSubstractCreditsDebitsByMonth,
        formatMillionFormat,
      }}
    >
      {children}
    </FlowCashContext.Provider>
  );
}
