import LoadingPage from "../utils/LoadingPage/LoadingPage";
import "./FlowCash.css";

function FlowCashUI(props) {
  const {
    operationActivitiesCharge,
    operationActivitiesPayment,
    operationActivities,
    investmentActivities,
    financingActivities,
    getValueByMonthByNiifId,
    getTotalMonthValueByNiif,
    tableRef,
    onDownload,
    onDownloadPDF,
    exportCellInfo,
    exportTotalInfo,
    getInitialBalance,
    getTotalFlowCash,
    getTotalComprobation,
    getComprobation,
    currentFormat,
    loading,
    goToCharts,
    fontSize,
    increaseFontSize,
    decreaseFontSize,
  } = props;

  return (
    <div className="">
      {!loading ? (
        <>
          <div className="row bg-light gx-0 pe-1 align-items-center">
            <div className="col-1 py-1 mx-1 text-end">
              <button
                className="btn btn-primary w-50"
                onClick={increaseFontSize}
              >
                +
              </button>
            </div>
            <div className="col-1 py-1 mx-1 text-start">
              <button
                className="btn btn-primary w-50"
                onClick={decreaseFontSize}
              >
                -
              </button>
            </div>
            <div className="col-3 py-1 ms-auto">
              <button className="btn btn-primary w-100" onClick={goToCharts}>
                Gráficas
              </button>
            </div>
          </div>
          <table
            className="table table-warning table-striped table-bordered table-hover"
            style={{ fontSize: `${fontSize}px` }}
            ref={tableRef}
            id="flowCashTable"
          >
            <tbody>
              {/* Header table */}
              <tr className="subtitle sticky-top">
                <th colSpan={3} className="total-flow-cash">
                  [510000] Estado de flujos de efectivo, metodo directo
                </th>
                <th className="total-flow-cash">Ene</th>
                <th className="total-flow-cash">Feb</th>
                <th className="total-flow-cash">Mar</th>
                <th className="total-flow-cash">Abr</th>
                <th className="total-flow-cash">May</th>
                <th className="total-flow-cash">Jun</th>
                <th className="total-flow-cash">Jul</th>
                <th className="total-flow-cash">Ago</th>
                <th className="total-flow-cash">Sep</th>
                <th className="total-flow-cash">Oct</th>
                <th className="total-flow-cash">Nov</th>
                <th className="total-flow-cash">Dic</th>
                <th className="total-flow-cash">Total</th>
              </tr>

              {/* Init Operation Activities */}

              {/* Title Operation  */}

              <tr className="title ">
                <th colSpan={3} className="operation-activities freezeColumn">
                  Flujos de efectivo procedentes de (utilizados en) actividades
                  de operacion [sinopsis]
                </th>
                <th colSpan={13} className="operation-activities"></th>
              </tr>

              {/* Header Operation charge Activities */}

              <tr className="table-danger">
                <th
                  rowSpan={
                    operationActivitiesCharge.length +
                    operationActivitiesPayment.length +
                    operationActivities.length +
                    2
                  }
                  className="subtitle  operation-activities freezeColumn"
                >
                  Actividades de Operacion
                </th>
                <th
                  colSpan={2}
                  scope="col"
                  className="operation-activities-charge freezeColumn2"
                >
                  Clases de cobros por actividades de operacion [sinopsis]
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    operationActivitiesCharge,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo(i, operationActivitiesCharge, true);
                      }}
                      className="operation-activities-charge text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalMonthValueByNiif(
                    "T",
                    operationActivitiesCharge,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo("T", operationActivitiesCharge, true);
                      }}
                      className="operation-activities-charge text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              {/* Body Operation charge Activities */}

              {operationActivitiesCharge.map((charge, i) => {
                return (
                  <tr className="table-danger ">
                    {i === 0 && (
                      <th
                        rowSpan={operationActivitiesCharge.length}
                        className="operation-activities-charge  freezeColumn2"
                        scope="col"
                      >
                        Cobros
                      </th>
                    )}

                    {getValueByMonthByNiifId("T", [charge.id], false) !== 0 && (
                      <>
                        <td scope="row" className="text-nowrap freezeColumn3">
                          {charge.name}
                        </td>

                        {[...Array(12).keys()].map((i) => {
                          const value = getValueByMonthByNiifId(
                            i,
                            [charge.id],
                            false
                          );
                          return (
                            <td
                              onClick={() => {
                                exportCellInfo(i, [charge.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })}

                        {(function () {
                          const value = getValueByMonthByNiifId(
                            "T",
                            [charge.id],
                            false
                          );
                          return (
                            <td
                              onClick={() => {
                                exportCellInfo("T", [charge.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })()}
                      </>
                    )}
                  </tr>
                );
              })}

              {/* Header Operation Payment Activities */}

              <tr className="table-danger">
                <th
                  colSpan={2}
                  scope="col"
                  className="operation-activities-payment freezeColumn2"
                >
                  Clases de pagos en efectivo procedentes de actividades de
                  operacion [resumen]
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    operationActivitiesPayment,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo(i, operationActivitiesPayment);
                      }}
                      className="operation-activities-payment text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalMonthValueByNiif(
                    "T",
                    operationActivitiesPayment,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo("T", operationActivitiesPayment);
                      }}
                      className="operation-activities-payment text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              {/* Body Operation Payment Activities */}

              {operationActivitiesPayment.map((item, i) => {
                return (
                  <tr>
                    {i === 0 && (
                      <th
                        rowSpan={operationActivitiesPayment.length}
                        className="operation-activities-payment  freezeColumn2"
                        scope="col"
                      >
                        Pagos
                      </th>
                    )}

                    {getValueByMonthByNiifId("T", [item.id], false) !== 0 && (
                      <>
                        <td scope="row" className="freezeColumn3">
                          {item.name}
                        </td>

                        {[...Array(12).keys()].map((i) => {
                          const value = getValueByMonthByNiifId(
                            i,
                            [item.id],
                            false
                          );
                          return (
                            <td
                              onClick={() => {
                                exportCellInfo(i, [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })}

                        {(function () {
                          const value = getValueByMonthByNiifId(
                            "T",
                            [item.id],
                            false
                          );

                          return (
                            <td
                              onClick={() => {
                                exportCellInfo("T", [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })()}
                      </>
                    )}
                  </tr>
                );
              })}

              {/* Body Operation Rest Activities */}

              {operationActivities.map((item, i) => {
                return (
                  <tr>
                    {getValueByMonthByNiifId("T", [item.id], false) !== 0 && (
                      <>
                        <td colSpan={2} scope="row" className="freezeColumn2">
                          {item.name}
                        </td>
                        {[...Array(12).keys()].map((i) => {
                          const value = getValueByMonthByNiifId(
                            i,
                            [item.id],
                            false
                          );

                          return (
                            <td
                              onClick={() => {
                                exportCellInfo(i, [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })}

                        {(function () {
                          const value = getValueByMonthByNiifId(
                            "T",
                            [item.id],
                            false
                          );

                          return (
                            <td
                              onClick={() => {
                                exportCellInfo("T", [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })()}
                      </>
                    )}
                  </tr>
                );
              })}

              {/* Total */}

              <tr>
                <th
                  colSpan={3}
                  className="subtitle operation-activities freezeColumn"
                >
                  TOTAL Flujos de efectivo netos procedentes de (utilizados en)
                  actividades de operacion{" "}
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    [
                      ...operationActivitiesCharge,
                      ...operationActivitiesPayment,
                      ...operationActivities,
                    ],
                    false
                  );

                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo(i, [
                          ...operationActivitiesCharge,
                          ...operationActivitiesPayment,
                          ...operationActivities,
                        ]);
                      }}
                      className="operation-activities text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalMonthValueByNiif(
                    "T",
                    [
                      ...operationActivitiesCharge,
                      ...operationActivitiesPayment,
                      ...operationActivities,
                    ],
                    false
                  );

                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo("T", [
                          ...operationActivitiesCharge,
                          ...operationActivitiesPayment,
                          ...operationActivities,
                        ]);
                      }}
                      className="operation-activities text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              {/* End Operation Activities */}

              {/* Init Investment Activities */}

              <tr>
                <th
                  colSpan={3}
                  className="subtitle investment-activities freezeColumn"
                >
                  Flujos de efectivo procedentes de (utilizados en) actividades
                  de inversion [sinopsis]
                </th>
                <th
                  colSpan={13}
                  className="subtitle investment-activities"
                ></th>
              </tr>

              {investmentActivities.map((item, i) => {
                return (
                  <tr>
                    {i === 0 && (
                      <th
                        rowSpan={investmentActivities.length}
                        className="subtitle  investment-activities freezeColumn m-0 g-0"
                      >
                        Actividades de Inversión
                      </th>
                    )}

                    {getValueByMonthByNiifId("T", [item.id], false) !== 0 && (
                      <>
                        <td colSpan={2} scope="row" className="freezeColumn2 m-0 g-0">
                          {item.name}
                        </td>
                        {[...Array(12).keys()].map((i) => {
                          const value = getValueByMonthByNiifId(
                            i,
                            [item.id],
                            false
                          );
                          return (
                            <td
                              onClick={() => {
                                exportCellInfo(i, [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })}

                        {(function () {
                          const value = getValueByMonthByNiifId(
                            "T",
                            [item.id],
                            false
                          );
                          return (
                            <td
                              onClick={() => {
                                exportCellInfo("T", [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })()}
                      </>
                    )}
                  </tr>
                );
              })}

              {/* Total */}

              <tr>
                <th
                  colSpan={3}
                  className="subtitle investment-activities freezeColumn"
                >
                  Flujos de efectivo netos procedentes de (utilizados en)
                  actividades de inversion
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    investmentActivities,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo(i, investmentActivities);
                      }}
                      className="investment-activities text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalMonthValueByNiif(
                    "T",
                    investmentActivities,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo("T", investmentActivities);
                      }}
                      className="investment-activities text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              {/* End Investment Activities */}

              {/* Init Financing Activities */}

              <tr>
                <th
                  colSpan={3}
                  className="subtitle financing-activities freezeColumn"
                >
                  Flujos de efectivo procedentes de (utilizados en) actividades
                  de financiacion [sinopsis]
                </th>
                <th
                  colSpan={13}
                  className="subtitle financing-activities"
                ></th>
              </tr>

              {financingActivities.map((item, i) => {
                return (
                  <tr>
                    {i === 0 && (
                      <th
                        rowSpan={financingActivities.length}
                        className="subtitle  financing-activities freezeColumn"
                      >
                        Actividades de Financiación
                      </th>
                    )}

                    {getValueByMonthByNiifId("T", [item.id], false) !== 0 && (
                      <>
                        <td colSpan={2} scope="row" className="freezeColumn2">
                          {item.name}
                        </td>
                        {[...Array(12).keys()].map((i) => {
                          const value = getValueByMonthByNiifId(
                            i,
                            [item.id],
                            false
                          );

                          return (
                            <td
                              onClick={() => {
                                exportCellInfo(i, [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })}

                        {(function () {
                          const value = getValueByMonthByNiifId(
                            "T",
                            [item.id],
                            false
                          );
                          return (
                            <td
                              onClick={() => {
                                exportCellInfo("T", [item.id]);
                              }}
                              className="text-end"
                              style={{ color: value < 0 ? "red" : "black" }}
                            >
                              {currentFormat(value)}
                            </td>
                          );
                        })()}
                      </>
                    )}
                  </tr>
                );
              })}

              {/* Total */}

              <tr>
                <th
                  colSpan={3}
                  className="subtitle financing-activities freezeColumn"
                >
                  TOTAL Flujos de efectivo netos procedentes de (utilizados en)
                  actividades de financiacion
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    financingActivities,
                    false
                  );

                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo(i, investmentActivities);
                      }}
                      className="financing-activities text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalMonthValueByNiif(
                    "T",
                    financingActivities,
                    false
                  );
                  return (
                    <th
                      onClick={() => {
                        exportTotalInfo("T", investmentActivities);
                      }}
                      className="financing-activities text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              {/* End Financing Activities */}

              {/* Init Totals */}

              <tr>
                <th colSpan={3} className="subtitle freezeColumn">
                  Incremento (disminucion) neto de efectivo y equivalentes al
                  efectivo, antes del efecto de los cambios en la tasa de cambio
                </th>

                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    [
                      ...operationActivitiesCharge,
                      ...operationActivitiesPayment,
                      ...operationActivities,
                      ...investmentActivities,
                      ...financingActivities,
                    ],
                    false
                  );

                  return (
                    <th
                      className="text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}
                <th className="text-end">{}</th>
              </tr>
              <tr>
                <th colSpan={3} className="subtitle freezeColumn">
                  Efectos de la variacion en la tasa de cambio sobre el efectivo
                  y equivalentes al efectivo [sinopsis]
                </th>
              </tr>
              <tr>
                <th colSpan={3} className="subtitle freezeColumn">
                  Efectos de la variacion en la tasa de cambio sobre el efectivo
                  y equivalentes al efectivo
                </th>
                {[...Array(13).keys()].map((i) => (
                  <th className="text-end">0</th>
                ))}
              </tr>
              <tr>
                <th colSpan={3} className="subtitle freezeColumn">
                  Incremento (disminucion) neto de efectivo y equivalentes al
                  efectivo
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalMonthValueByNiif(
                    i,
                    [
                      ...operationActivitiesCharge,
                      ...operationActivitiesPayment,
                      ...operationActivities,
                      ...investmentActivities,
                      ...financingActivities,
                    ],
                    false
                  );

                  return (
                    <th
                      className="text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalMonthValueByNiif(
                    "T",
                    [
                      ...operationActivitiesCharge,
                      ...operationActivitiesPayment,
                      ...operationActivities,
                      ...investmentActivities,
                      ...financingActivities,
                    ],
                    false
                  );
                  return (
                    <th
                      className="text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              <tr>
                <th colSpan={3} className="subtitle freezeColumn">
                  Efectivo y equivalentes al efectivo al principio del periodo
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getInitialBalance(i, false);

                  return (
                    <th
                      className="text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getInitialBalance("T", false);

                  return (
                    <th
                      className="text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              <tr>
                <th colSpan={3} className=" title total-flow-cash freezeColumn">
                  Efectivo y equivalentes al efectivo al final del periodo
                </th>
                {[...Array(12).keys()].map((i) => {
                  const value = getTotalFlowCash(i, false);

                  return (
                    <th
                      className="total-flow-cash text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })}

                {(function () {
                  const value = getTotalFlowCash("T", false);
                  return (
                    <th
                      className="total-flow-cash text-end"
                      style={{ color: value < 0 ? "red" : "black" }}
                    >
                      {currentFormat(value)}
                    </th>
                  );
                })()}
              </tr>

              {/* COMPROBACIÓN */}
              <tr>
                <th colSpan={16}></th>
              </tr>
              <tr>
                <th colSpan={16}></th>
              </tr>
              <tr>
                <th colSpan={3} className=" title total-flow-cash freezeColumn">
                  SALDO DE EFECTIVO Y EQUIVALENTES DE EFECTIVO
                </th>
                {[...Array(12).keys()].map((i) => (
                  <th className="total-flow-cash text-end">
                    {getTotalComprobation(i, true)}
                  </th>
                ))}
                <th className="total-flow-cash text-end">
                  {getTotalComprobation("T", true)}
                </th>
              </tr>
              <tr>
                <th colSpan={3} className=" title total-flow-cash freezeColumn">
                  COMPROBACIÓN
                </th>
                {[...Array(12).keys()].map((i) => (
                  <th className="total-flow-cash text-end">
                    {getComprobation(i, true)}
                  </th>
                ))}
                <th className="total-flow-cash text-end">
                  {getComprobation("T", true)}
                </th>
              </tr>
            </tbody>
          </table>
          <div className="text-center row">
            <div className="col-4">
              <button className="btn btn-primary w-75" onClick={onDownload}>
                {" "}
                Exportar Archivo Excel{" "}
              </button>
            </div>
            <div className="col-4">
              <button className="btn btn-primary w-75" onClick={onDownloadPDF}>
                {" "}
                Exportar Archivo PDF{" "}
              </button>
            </div>
          </div>
        </>
      ) : (
        <LoadingPage loadingTitle={"Cargando información..."} />
      )}
    </div>
  );
}

export { FlowCashUI };
