export async function modelPaymentInfo(epayResponse) {
  
   const paymentInfo = {
    email: epayResponse.x_customer_email,
    plan: epayResponse.x_description.substring(0,epayResponse.x_description.indexOf("-")),
    voucher: epayResponse.x_id_factura,
    paymentState: epayResponse.x_cod_transaction_state === 1 ? true : false,
  };

  return paymentInfo;
}
