import React from "react";
import {
  BsTelephone,
  BsShop,
  BsPerson,
} from "react-icons/bs";

function ShoppingCartUI(props) {
  const { formik, planArray, planChange } = props;
  return (
    <div className="ownContainer">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="card mt-5 mb-4">
            <div className="card-body">
              <div className="mb-3 text-center">
                <p className="fw-bold fs-3">Registrar un nueva compra</p>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-11">
                    <label htmlFor="nameInput" className="form-text">
                      Nombre
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsPerson size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        value={formik.values.name}
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-2">
                    <label htmlFor="typeDocInput" className="form-text">
                      Tipo de documento
                    </label>
                    <select
                      className="form-select form-select-sm"
                      id="typeDocSelect"
                      value={formik.values.typeDoc}
                      onChange={(e) =>
                        formik.setFieldValue("typeDoc", e.target.value)
                      }
                    >
                      <option value="">Selección</option>
                      <option value="CC">Cedula de ciudadanía</option>
                      <option value="CE">Cedula de extranjería</option>
                      <option value="NIT">NIT</option>
                    </select>
                  </div>

                  <div className="col-9">
                    <label htmlFor="idInput" className="form-text">
                      Número de identificación
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">#</span>
                      <input
                        type="number"
                        className="form-control"
                        id="idInput"
                        value={formik.values.id}
                        onChange={(e) =>
                          formik.setFieldValue("id", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-7">
                    <label htmlFor="addressInput" className="form-text">
                      Dirección
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsShop size="1rem" color="black" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="addressInput"
                        value={formik.values.address}
                        onChange={(e) =>
                          formik.setFieldValue("address", e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <label htmlFor="phoneInput" className="form-text">
                      Teléfono
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">
                        <BsTelephone size="1rem" color="black" />
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        id="phoneInput"
                        value={formik.values.phone}
                        onChange={(e) =>
                          formik.setFieldValue("phone", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-11">
                    <label htmlFor="emailInput" className="form-text">
                      Correo Electrónico
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">@</span>
                      <input
                        type="text"
                        className="form-control"
                        id="emailInput"
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue("email", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="row justify-content-center">
                  <div className="col-6">
                    <label htmlFor="planInput" className="form-text">
                      Plan
                    </label>
                    <select
                      className="form-select"
                      id="planSelect"
                      value={formik.values.plan}
                      onChange={(e) => {
                        formik.setFieldValue("plan", e.target.value);
                        planChange(e.target.value);
                      }}
                    >
                      <option value="">Selección Plan</option>
                      {planArray.map((plan) => {
                        return (
                          <option key={plan.id} value={plan.id}>
                            {plan.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-5">
                    <label htmlFor="priceInput" className="form-text">
                      Monto
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">$</span>
                      <input
                        type="text"
                        className="form-control"
                        id="priceInput"
                        value={formik.values.price}
                        onChange={(e) =>
                          formik.setFieldValue("price", e.target.value)
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row bg-danger text-danger bg-opacity-25">
                {Object.keys(formik.errors).map(
                  (key) =>
                    formik.errors[key] && (
                      <small key={key}>
                        <li>{formik.errors[key]}</li>
                      </small>
                    )
                )}
              </div>

              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-25"
                  onClick={formik.handleSubmit}
                >
                  Comprar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCartUI;
