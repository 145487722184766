function LoadingPageUI(props) {
  const { loadingTitle } = props;
  return (
    <div className="d-flex flex-column justify-content-center align-items-center ownContainer">
      
      <div className="spinner-border text-primary mt-5" role="status">
        <span className="visually-hidden">{loadingTitle ? loadingTitle : ""}</span>
      </div>
      <p className="mb-5">{loadingTitle ? loadingTitle : ""}</p>
    </div>
  );
}

export { LoadingPageUI };
