export function modelPlan(plan) {

  return {
    id: plan.id,
    name: plan.name,
    reports: plan.reports,
    price: plan.price,
    state: plan.state ? plan.state : "A",
  };
}

export async function modelPlansArray(plansArray) {
  return await plansArray.map((plan) => {
    return modelPlan(plan)
  });  
}
