import React from "react";
import { LoadingModalUI } from "./LoadingModalUI";

function LoadingModal(props) {
  const { loadingTitle, doneTitle, loading, functionButton } = props;

  return (
    <LoadingModalUI
      loadingTitle={loadingTitle}
      doneTitle={doneTitle}
      loading={loading}
      functionButton={functionButton ? functionButton : ()=>{}}
    />
  );
}

export default LoadingModal;
