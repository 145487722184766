export function changeFromNumberToCurrency(number) {
  return new Intl.NumberFormat("es-CO").format(number);
}

export function changeFromCurrencyToNumber(currency){
    return parseFloat(currency.replace(/\./g, '').replace(',', '.'));
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return `${day}/${month}/${year}`;
}