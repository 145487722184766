import React, { useEffect } from "react";
import CompanyDashboardUI from "./CompanyDashboardUI";
import { CompanyService } from "../../services/CompanyService";
import { useState } from "react";

function CompanyDashboard() {
  const [loading, setLoading] = useState(false);
  const [companiesInfo, setCompaniesInfo] = useState([]);
  const [mesaggeModal, setMessageModal] = useState("");
  const [titleModal, setTitleModal] = useState("");
  useEffect(() => {
    setLoading(true);
    setMessageModal("Cargando información");
    loadData();
  }, []);

  async function loadData() {
    const companyService = new CompanyService();

    await companyService.getCompaniesInfo().then(async (response) => {
      if (response.data.length > 0) {
        setLoading(false);
        setCompaniesInfo(response.data);
      }
    });
  }

  const deleteCompany = async (id) => {
    setTitleModal("Eliminar archivo");
    setLoading(true);
    const companyService = new CompanyService();
    await companyService.deleteCompany(id).then(async (response) => {
      console.log(response);
      if (response) {
        setMessageModal("Compañía eliminada correctamente");
        setLoading(false);
        loadData();
      }
    });
  };

  return (
    <CompanyDashboardUI
      loading={loading}
      companiesInfo={companiesInfo}
      deleteCompany={deleteCompany}
    />
  );
}

export default CompanyDashboard;
