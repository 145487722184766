import axios from "axios";

import { API_BASE_URL, ACCESS_TOKEN } from "../constants";

class NiifService {
  async getNiifs() {
    const url = API_BASE_URL + "/niif";

    try {
      const response = await axios({
        url: url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem(ACCESS_TOKEN)
          )}`,
        },
      });

      if (response.status === 200) {
        return response.data.map((niif) => modelNiif(niif));
      }
      return null;
    } catch (e) {
      throw "Error en consulta con el servidor";
    }
  }
}
function modelNiif(niif) {
  return {
    id: niif.id,
    name: niif.name,
    activity: { id: niif.activity.id, name: niif.activity.name },
    classification: niif.classification,
  };
}

export {NiifService}
