import { URL_COMPLETE } from "../constants";

export function modelPaymentRequestData(plan, client, amount) {
  return {
    //Parametros compra (obligatorio)
    name: plan + "¿Qué pasó con mi dinero?",
    description:
    plan + "- Licencia - ¿Qué pasó con mi dinero?"  +
      ", " +
      new Date().toLocaleDateString("en-GB") + ", " + client.email,
    amount: amount,
    currency: "cop",
    tax_base: "0",
    tax: "0",
    country: "co",
    lang: "es",

    //Onpage="false" - Standard="true"
    external: "true",

    //Atributos opcionales
    confirmation: URL_COMPLETE + "/paymentResponse",
    response: URL_COMPLETE + "/paymentResponse",

    //Atributos cliente
    name_billing: client.name,
    address_billing: client.address,
    email_billing: client.email,
    type_doc_billing: client.typeDoc,
    mobilephone_billing: client.phone,
    number_doc_billing: client.id,

    //atributo deshabilitación metodo de pago
    methodsDisable: ["PSE", "SP", "CASH", "DP"],
  };
}
