import { Link } from "react-router-dom";
import { BiBuildings } from "react-icons/bi";
import { URL_ROUTE } from "../../constants";

function NavBarUI(props) {
  const { auth, signOut, downloadFile } = props;
  return (
    <nav className="navbar navbar-expand-lg bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand " to={URL_ROUTE + "/main"}>
          <BiBuildings />
          {auth ? <>{auth.company.name} </> : "  ¿Qué pasó con el dinero?"}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            {auth && auth.role !== "USER" && (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dashboard
                  </a>
                  <ul className="dropdown-menu">
                    {auth && auth.role === "ADMIN" && (
                      <>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={URL_ROUTE + "/companyDashboard"}
                          >
                            Empresas
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </>
                    )}
                    <li>
                      <Link
                        className="dropdown-item"
                        to={URL_ROUTE + "/userDashboard"}
                      >
                        Usuarios
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Registrar
                  </a>
                  <ul className="dropdown-menu">
                    {auth && auth.role === "ADMIN" && (
                      <>
                        <li>
                          <Link
                            className="dropdown-item"
                            to={URL_ROUTE + "/companyRegister"}
                          >
                            Empresa
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </>
                    )}
                    <li>
                      <Link
                        className="dropdown-item"
                        to={URL_ROUTE + "/userRegister"}
                      >
                        Usuario
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            {auth && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Descargar Plantilla
                </a>
                <ul className="dropdown-menu">
                  <li onClick={downloadFile}>
                    <Link className="nav-link">Descargar</Link>
                  </li>
                </ul>
              </li>
            )}
            {auth && (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to={URL_ROUTE + "/uploadFile"}>
                    Cargar Archivo
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={URL_ROUTE + "/historyFile"}>
                    Flujo de Efectivo
                  </Link>
                </li>
              </>
            )}
          </ul>
          {auth && (
            <button className="btn btn-primary" onClick={signOut}>
              Cerrar Sesión
            </button>
          )}
        </div>
      </div>
    </nav>
  );
}

export { NavBarUI };
