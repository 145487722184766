import React from "react";
import { LoadingPageUI } from "./LoadingPageUI";

function LoadingPage(props) {
  const { loadingTitle } = props;

  return (
    <LoadingPageUI
    loadingTitle={loadingTitle}
    />
  );
}

export default LoadingPage;
