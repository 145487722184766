import React from 'react'
import { WithoutLicenseUI } from './WithoutLicenseUI'
import useAuth from '../../hooks/useAuth'

function WithoutLicense() {

    const {auth} = useAuth();

  return (
    <WithoutLicenseUI company= {auth.company.name} />
  )
}

export default WithoutLicense