import React, { useEffect, useState } from 'react'
import PaymentResponseUI from './PaymentResponseUI'
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { URL_ROUTE } from '../../constants';
import { modelPaymentInfo } from '../../models/PaymentModel';

function PaymentResponse() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

 
    useEffect(() => {
      const paymentRef = searchParams.get("ref_payco");
      
      paymentRef === "undefined" ? setLoading(false) : getPaymentInfo(searchParams.get("ref_payco"))
    }, [])
    
    async function getPaymentInfo(paymentId){
        const url = "https://secure.epayco.co/validation/v1/reference/" + paymentId;

        const response = await axios({
            url: url,
            method: "GET",
        });

        console.log(response);

        if(response.data.success){
            const paymentInfo = await modelPaymentInfo(response.data.data);
            console.log(paymentInfo);
            if(paymentInfo.paymentState){
              navigate(URL_ROUTE + "/generateLicense", { state: paymentInfo });
            }else {
              setLoading(false);
            }
        } 

        

       
    }
    
    function goToShoppingCar() {
      navigate(URL_ROUTE + "/shoppingCart");
  }

  return (
    <PaymentResponseUI loading={loading} goToShoppingCar={goToShoppingCar}/>
  )
}

export default PaymentResponse