export function modelUser(user) {
  return {
    id: user.id,
    name: user.name,
    lastname: user.lastname,
    password: user.password,
    phone: user.phone,
    company: user.company,
    role: user.role ? user.role : "USER",
    state: user.state ? user.state : "A",
  };
}
