import React from 'react'
import useAuth from '../../hooks/useAuth.js'
import { WelcomeUI } from './WelcomeUI.jsx'

function Welcome() {
  const {auth} = useAuth();

  return (
    <WelcomeUI auth={auth}/>
  )
}

export default Welcome