import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import exportFromJSON from "export-from-json";
import { FileModel } from "../../models/FileModel";
import { DataService } from "../../services/DataService";
import { FileService } from "../../services/FileService";
import { HistoryUI } from "./HistoryUI";
import useAxios from "../../hooks/useAxios";

function History() {
  const { generalRequest } = useAxios();
  const { auth } = useAuth();
  const [files, setFiles] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [companySelected, setCompanySelected] = useState();

  const [loading, setLoading] = useState(false);
  const [mesaggeModal, setMessageModal] = useState("");
  const [titleModal, setTitleModal] = useState("");

  useEffect(() => {
    auth.role !== "ADMIN" ? loadFiles(auth.company.id) : loadCompanies();
  }, []);

  useEffect(() => {
    auth.role === "ADMIN" && companySelected && loadFiles(companySelected);
  }, [companySelected]);

  async function loadCompanies() {
    await generalRequest("/company", "GET", false).then((response) => {
      setCompanies(response.data);
    });
  }

  async function loadFiles(companyId) {
    const fileService = new FileService();
    await fileService.getFilesByCompany(companyId).then((response) => {
      setFiles(response);
    });
  }

  const downloadFileData = async (e) => {
    const id = e.target.id;
    if (id) {
      setTitleModal("Descargando Archivo");
      setLoading(true);
      const dataService = new DataService();
      const fileModel = new FileModel();

      await dataService.getData(e.target.id).then(async (response) => {
        await fileModel.fileExportModel(response.data).then((response) => {
          setLoading(false);
          setMessageModal("Archivo listo para descarga");
          const lengthFile = 1000000;
          let aux = 0;
          for (let index = 0; index < response.length; index += lengthFile) {
            aux++;
            exportFromJSON({
              data: response.slice(index, index + lengthFile),
              fileName:
                "Flujo Efectivo_FE_" + auth.company.name + "_" + id + "_" + aux,
              exportType: "csv",
              delimiter: ";",
            });
          }
        });
      });
    }
  };
  const downloadFileOther = async (e) => {
    const id = e.target.id;
    if (id) {
      setTitleModal("Descargando Archivo");
      setLoading(true);
      const dataService = new DataService();
      const fileModel = new FileModel();

      await dataService.getDataOthers(e.target.id).then(async (response) => {
        await fileModel.fileExportModel(response.data).then((response) => {
          setLoading(false);
          setMessageModal("Archivo listo para descarga");
          const lengthFile = 1000000;
          let aux = 0;
          for (let index = 0; index < response.length; index += lengthFile) {
            aux++;
            exportFromJSON({
              data: response.slice(index, index + lengthFile),
              fileName:
                "Flujo Efectivo_Bancos_" +
                auth.company.name +
                "_" +
                id +
                "_" +
                aux,
              exportType: "csv",
              delimiter: ";",
            });
          }
        });
      });
    }
  };

  const deleteFile = async (e) => {
    console.log("Entrando buton");
    setTitleModal("Eliminar archivo");
    setLoading(true);
    const fileService = new FileService();
    const id = e.target.id;
    await fileService.deleteFileById(id).then(async (response) => {
      setMessageModal(response.message);
      setLoading(false);
    });
  };

  return (
    <HistoryUI
      auth={auth}
      files={files}
      downloadFileData={downloadFileData}
      downloadFileOther={downloadFileOther}
      deleteFile={deleteFile}
      companiesArray={companies}
      companySelected={companySelected}
      setCompanySelected={setCompanySelected}
      loading={loading}
      mesaggeModal={mesaggeModal}
      titleModal={titleModal}
    />
  );
}

export default History;
