import React from 'react'
import { useNavigate } from 'react-router-dom';
import { URL_ROUTE } from '../../constants';
import { ErrorsUI } from './ErrorsUI';

function Errors(props) {

    const navigate = useNavigate();
    const {errorType} = props;
    var textError;
    var buttonLabel;
    var buttonFunction;

    if(errorType==="noLogin"){
        textError = "Debes iniciar sesión nuevamente";
        buttonLabel = "Iniciar Sesión"
        buttonFunction = ()=>{navigate(URL_ROUTE + "/")}

    }
  return (
    <ErrorsUI textError={textError} buttonLabel={buttonLabel} buttonFunction={buttonFunction} />
  )
}

export default Errors